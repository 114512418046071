import SuitCard from "./SuitCard";

const SuitsList = ({ event, selectedPackage }) => {
  return (
    <div className="suits-list">
      {selectedPackage.suits.map((el, id) => {
        return (
          <SuitCard
            key={selectedPackage.id + "-" + id}
            suit={el}
            event={event}
            selectedPackage={selectedPackage.packageName}
          />
        );
      })}
    </div>
  );
};

export default SuitsList;
