import { useEffect } from "react";
import { BsCheckCircle } from "react-icons/bs";
import { BiErrorCircle } from "react-icons/bi";

import { useDispatch, useSelector } from "react-redux";
import { clearMessage, selectMessage } from "features/appSlice";

const ServiceMsg = () => {
  const message = useSelector(selectMessage);
  const dispatch = useDispatch();

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        dispatch(clearMessage());
      }, 3000);
    }
  }, [message, dispatch]);

  if (message)
    return (
      <div className={`service-msg service-msg-${message.type}`}>
        {message.type === "success" && <BsCheckCircle />}
        {message.type === "error" && <BiErrorCircle />}
        <p>{message.msg}</p>
      </div>
    );

  return null;
};

export default ServiceMsg;
