import { Link } from "react-router-dom";
import MainSlider from "./MainSlider";
import EventsList from "./EventsList";
import { ReactComponent as EmptyTours } from "icons/empty-tours.svg";
import { useGetEventsQuery, useGetFutureEventsQuery } from "app/eventsApi";
import Telegram from "./Telegram";
import UpcommingEvents from "./UpcommingEvents";

const Home = () => {
  const { data } = useGetEventsQuery();
  const { data: upcommingEvents } = useGetFutureEventsQuery();

  if (data && !data.length)
    return (
      <main className="error-main empty-tours pad">
        <div className="container">
          <div className="empty-tours-text">
            <h4>Нет доступных сборов</h4>
            <p className="error-title">
              В данный момент нет доступных сборов. Новые сборы появятся скоро!
            </p>
          </div>
          <EmptyTours />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Link to="archive" className="text-link" data-theme="light">
              Архив событий
            </Link>
          </div>
        </div>
      </main>
    );

  return (
    <main className="main pad">
      <div className="container">
        <MainSlider />
        <Telegram />
        <EventsList eventsData={data} />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "2rem",
          }}
        >
          <Link to="archive" className="text-link" data-theme="light">
            Архив событий
          </Link>
        </div>
        {upcommingEvents && upcommingEvents.length > 0 && (
          <UpcommingEvents data={upcommingEvents} />
        )}
      </div>
    </main>
  );
};

export default Home;
