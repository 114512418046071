import { useNavigate } from "react-router-dom";
import { BsCheckCircle } from "react-icons/bs";

const Status = () => {
  const navigate = useNavigate();

  return (
    <main className="status-main pad">
      <div className="container">
        <BsCheckCircle className="icon-success" />
        <h4>Оплата прошла успешно!</h4>
        <p>
          Оплата прошла успешно! Вы можете увидеть детальную информацию о
          бронировании в своем личном кабинете или вернуться на гланую страницу
        </p>
        <button
          className="btn btn-primary"
          onClick={() => navigate("/account")}
        >
          История бронирований
        </button>
        <button className="btn btn-outline" onClick={() => navigate("/")}>
          На главную страницу
        </button>
      </div>
    </main>
  );
};

export default Status;
