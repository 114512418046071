import { selectIsLoading } from "features/appSlice";
import { useSelector } from "react-redux";

const Loader = () => {
  const loading = useSelector(selectIsLoading);

  return (
    <div className={`${loading ? "loader active" : "loader"}`}>
      <div className="loader-progress"></div>
    </div>
  );
};

export default Loader;
