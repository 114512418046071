import { useNavigate } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import { useMediaQuery } from "hooks";
import { ReactComponent as IconWarning } from "icons/icon-warning.svg";
import DetailsSuit from "./DetailsSuit";
import ReservationExp from "components/ReservationExp";

import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "features/userSlice";
import { setLastLocation, setMessage } from "features/appSlice";
import {
  selectReservation,
  selectReservationExpiry,
  selectReservationId,
  selectValid,
} from "features/reservationSlice";
import {
  useGetReservationMutation,
  useUpdateReservationMutation,
} from "app/eventsApi";

const DetailsCard = () => {
  const tablet = useMediaQuery("tablet");
  const navigate = useNavigate();

  const reservation = useSelector(selectReservation);
  const reservation_uuid = useSelector(selectReservationId);
  const reservationExpiry = useSelector(selectReservationExpiry);
  const valid = useSelector(selectValid);
  const user = useSelector(selectUser);
  const [updateReservation] = useUpdateReservationMutation();
  const [getReservation] = useGetReservationMutation();
  const dispatch = useDispatch();

  // Scroll to registration if not logged in
  const scrollToAuth = () => {
    window.scroll({ top: 120, behavior: "smooth" });
  };

  // Edit reservation
  const handleClick = (slug) => {
    dispatch(setLastLocation("/reservation"));
    navigate(`/event/${slug}`);
  };

  // Check all forms for errors
  const checkForms = () => {
    const forms = [...document.querySelectorAll(".reservation-card form")];
    // forms.reverse().map((el) => el.requestSubmit()); //--- doesn't work in Safari
    forms
      .reverse()
      .map((el) =>
        el.dispatchEvent(
          new Event("submit", { cancelable: true, bubbles: true })
        )
      );
  };

  // Checkout page after all checks
  const handleSubmit = async () => {
    if (!user) {
      return scrollToAuth();
    }
    if (!valid) {
      return checkForms();
    }
    try {
      await updateReservation({
        user_id: user.id,
        reservation_uuid,
        reservation,
      }).unwrap();
      navigate("/checkout");
    } catch (err) {
      dispatch(
        setMessage({ type: "error", msg: "Данные бронирования устарели" })
      );
      try {
        await getReservation({
          user_id: user.id,
        }).unwrap();
      } catch (err) {}
    }
  };

  return (
    <div
      className="reservation-details-container"
      aria-label="Детали бронирования"
    >
      {!tablet && (
        <article className="reservation-details-card card">
          {reservation.map((event, eventId) => {
            return (
              <div className="details-card-group" key={event.slug}>
                <h6>{event.header || "Мероприятие"}</h6>
                {event.suits.length !== 0 &&
                  event.suits.map((suit, suitId) => {
                    return (
                      <DetailsSuit
                        key={suit.id}
                        eventId={eventId}
                        suitId={suitId}
                        suit={suit}
                        event={event}
                        name={suit.name}
                        selectedPackage={suit.options.package}
                      />
                    );
                  })}
                {!event.suits.some((el) => el.reservation_type === 2) && (
                  <span
                    className="btn-inline"
                    onClick={() => handleClick(event.slug)}
                  >
                    + Добавить еще один номер
                  </span>
                )}
              </div>
            );
          })}
        </article>
      )}
      {reservationExpiry && (
        <ReservationExp reservationExpiry={reservationExpiry} />
      )}
      {(!user || !valid) && (
        <small className="warning">
          <IconWarning />
          {!user && !valid ? (
            <span>
              Перед переходом на страницу оплаты необходимо{" "}
              <span onClick={scrollToAuth} className="text-link">
                зарегистрироваться или войти
              </span>{" "}
              и заполнить все{" "}
              <span onClick={checkForms} className="text-link">
                обязательные поля
              </span>
            </span>
          ) : !user ? (
            <span>
              Перед переходом на страницу оплаты необходимо{" "}
              <span onClick={scrollToAuth} className="text-link">
                зарегистрироваться или войти
              </span>
            </span>
          ) : (
            <span>
              Перед переходом на страницу оплаты необходимо заполнить все{" "}
              <span onClick={checkForms} className="text-link">
                обязательные поля
              </span>
            </span>
          )}
        </small>
      )}
      <button
        className={
          !user || !valid
            ? "btn btn-primary-icon disabled"
            : "btn btn-primary-icon"
        }
        type="submit"
        onClick={handleSubmit}
      >
        Далее: финальные данные
        <BiArrowBack />
      </button>
    </div>
  );
};

export default DetailsCard;
