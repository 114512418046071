import { api } from "./api";

const userBalanceApi = api
  .enhanceEndpoints({
    addTagTypes: ["UserBalance"],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getUserBalance: builder.query({
        query: (userId) => `sales/user_balance?user_pk=${userId}`,
        transformResponse: (res) => res.data.user_balance,
        providesTags: ["UserBalance"],
        keepUnusedDataFor: 0,
      }),
      sendConfirmationCode: builder.mutation({
        query: (body) => ({
          url: "sales/send_confirmation_code",
          method: "POST",
          body,
        }),
      }),
      confirmBalancePayment: builder.mutation({
        query: (body) => ({
          url: "confirm_payment/",
          method: "POST",
          body,
        }),
      }),
      confirmInvoiceBalancePayment: builder.mutation({
        query: (body) => ({
          url: "pay_by_account/",
          method: "POST",
          body,
        }),
      }),
    }),
  });

export const {
  useGetUserBalanceQuery,
  useSendConfirmationCodeMutation,
  useConfirmBalancePaymentMutation,
  useConfirmInvoiceBalancePaymentMutation,
} = userBalanceApi;
