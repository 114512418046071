import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useGetEventInfoQuery } from "app/eventsApi";
import { Modal } from "components";
import { useCloseModal } from "components/Modal";
import { selectIsModal } from "features/appSlice";

const DiscountModal = () => {
  const handleClose = useCloseModal();
  const { id } = useParams();

  const { data } = useGetEventInfoQuery({ slug: id });
  const discount = useSelector(selectIsModal);

  if (!discount) return null;

  return (
    <Modal name="discount">
      <article className="discount">
        <h6>
          Ваша персональная скидка <span>{discount.discount_total}</span>
        </h6>
        <ul>
          {discount.discounts.map((el, id) => (
            <li key={id}>
              <p>{el.name}</p>
              <p>{el.rate}</p>
            </li>
          ))}
        </ul>
      </article>
      {data && (
        <>
          <h4>{data.title}</h4>
          {data.list.map((el, id) => (
            <article className="article" key={id}>
              <h6>{el.title}</h6>
              {el.description ? (
                el.list ? (
                  <details>
                    <summary>
                      <p>{el.description}</p>
                    </summary>
                    <ul>
                      {el.list.map((item, i) => (
                        <li key={i}>
                          <p>{item.name}</p>
                          <p>{item.summary}</p>
                        </li>
                      ))}
                    </ul>
                  </details>
                ) : (
                  <p>{el.description}</p>
                )
              ) : null}
            </article>
          ))}
        </>
      )}
      <footer>
        <p style={{ maxWidth: "50ch" }}>
          Ваше счастье, здоровье и впечатления бесценны! Оставайтесь с нами ❤️
        </p>
      </footer>
      <button className="btn btn-outline" onClick={handleClose}>
        Закрыть окно
      </button>
    </Modal>
  );
};

export default DiscountModal;
