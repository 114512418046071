import { forwardRef } from "react";

const InputTextArea = forwardRef(
  ({ label, error, helperLink, helperText, children, ...rest }, ref) => (
    <div className={error ? "field error" : "field"}>
      {label && (
        <label htmlFor={rest.id}>
          {label} {error && <span className="required-mark">*</span>}
        </label>
      )}
      <textarea
        ref={ref}
        {...rest}
        onInput={(e) => {
          e.target.style.height = "auto";
          e.target.style.height = e.target.scrollHeight + "px";
        }}
        rows="3"
        placeholder=" "
      />
      {helperText && !error && (
        <span className="helper-text">{helperText}</span>
      )}
      {error && error !== ` ` && <span className="error-msg">{error}</span>}
      {helperLink}
      {children}
    </div>
  )
);

export default InputTextArea;
