import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { useSelector } from "react-redux";
import { selectUser } from "features/userSlice";
import { useGetAccountMutation } from "app/authApi";

const RefetchAccount = () => {
  const { key } = useLocation();
  const user = useSelector(selectUser);
  const [getAccount] = useGetAccountMutation();

  useEffect(() => {
    const refresh = async () => {
      if (user) {
        try {
          await getAccount();
        } catch (err) {}
      }
    };
    refresh();
  }, [key, getAccount]); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};

export default RefetchAccount;
