import { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import { ReactComponent as Logo } from "icons/logo-white.svg";
// import data from "./tv.json";
import { useGetSlidesQuery } from "app/eventsApi";
import { BASE_URL } from "helpers/constants";

const Tv = () => {
  const [slide, setSlide] = useState(0);
  const [animate, setAnimate] = useState(false);
  const { data } = useGetSlidesQuery(undefined, { pollingInterval: 3600000 });
  const slides = data && data.length !== 0 ? [...data, data[0]] : [];

  useEffect(() => {
    const timer = setTimeout(() => {
      setAnimate((p) => (p = !p));
      slide + 1 <= slides.length - 1 ? setSlide((p) => p + 1) : setSlide(0);
    }, 40000);

    return () => clearTimeout(timer);
  });

  if (slides.length === 0) return null;

  return (
    <main className="tv" data-animate={animate || undefined}>
      <article className="tvInfo">
        <Logo className="tvLogo" />
        <div className="tvTitle">
          <h1>{slides[slide].title}</h1>
          <span>{slides[slide].date}</span>
          {slides[slide].short_desc && <p>{slides[slide].short_desc}</p>}
        </div>
        <div className="tvQr">
          <div className="qr">
            <QRCode
              value={slides[slide].url}
              size={160}
              bgColor="none"
              fgColor="#fff"
            />
          </div>
          <p>
            Бронируй с помощью QR кода — получи скидку{" "}
            {slides[slide].promo_code ? `${slides[slide].promo_code}%` : ""} по{" "}
            промокоду
          </p>
        </div>
      </article>
      <div className="tvImg">
        <div
          className="tvImgContainer"
          style={{
            top: `-${slide * 100}%`,
            transition: slide === 0 ? "none" : undefined,
          }}
          onTransitionEnd={() => {
            setAnimate(false);
            if (slide + 1 === slides.length) {
              setSlide(0);
            }
          }}
        >
          {slides.map((el, id) => (
            <img key={id} src={`${BASE_URL}${el.img_tv}`} alt={el.title} />
          ))}
        </div>
      </div>
    </main>
  );
};

export default Tv;
