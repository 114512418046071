import EventCard from "./EventCard";

const EventsList = ({ eventsData }) => {
  if (!eventsData)
    return (
      <section className="events" aria-label="список событий">
        {Array.from({ length: 2 }, (_, id) => id).map((el) => (
          <article className="card event-card" key={el} data-skeleton>
            <header className="event-card-header"></header>
            <div className="event-card-content">
              <span className="event-card-date">loading</span>
              <h5 className="event-card-title">Loading</h5>
              <button className="btn btn-primary">Loading</button>
            </div>
          </article>
        ))}
      </section>
    );

  return (
    <section className="events" aria-label="список событий">
      {eventsData?.map((event, id) => {
        return <EventCard event={event} key={id} />;
      })}
    </section>
  );
};

export default EventsList;
