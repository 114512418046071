import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { FiSettings, FiLogOut } from "react-icons/fi";
import { BsArrowRight } from "react-icons/bs";
import { ReactComponent as IconBonus } from "icons/icon_bonus.svg";
import { ReactComponent as IconSpinner } from "icons/icon-spinner.svg";
import { ReactComponent as IconSilver } from "icons/icon-status-silver.svg";
import { ReactComponent as IconGold } from "icons/icon-status-gold.svg";
import { ReactComponent as IconPremium } from "icons/icon-status-premium.svg";
import { useBool, useMediaQuery } from "hooks";
import AccountSettings from "./AccountSettings";
import OrdersList from "./OrdersList";
import BonusAbout from "./BonusAbout";
import CouponModal from "./CouponModal";
import ActivityModal from "./ActivityModal";
import BonusModal from "./BonusModal";

import { useSelector, useDispatch } from "react-redux";
import { useLogoutMutation } from "app/authApi";
import { useGetOrdersMutation } from "app/eventsApi";
import { selectToken, selectUser } from "features/userSlice";
import { clear } from "features/reservationSlice";
import { useGetUserActivityQuery, useGetUserBonusesQuery } from "app/bonusApi";
import { selectIsModal, setIsModal } from "features/appSlice";
import { usePrefetch } from "app/api";

const STATUS = {
  silver: {
    name: "Silver",
    icon: <IconSilver />,
  },
  gold: {
    name: "Gold",
    icon: <IconGold />,
  },
  premium: {
    name: "Premium",
    icon: <IconPremium />,
  },
};

const Account = () => {
  const location = useLocation();
  const tablet = useMediaQuery("tablet");
  const mobile = useMediaQuery("mobile");
  const showSettings = useBool();
  const animate = useBool(true);

  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const token = useSelector(selectToken);
  const modal = useSelector(selectIsModal);
  const [logout] = useLogoutMutation();
  const [getOrders, { data: orders }] = useGetOrdersMutation();
  const { data: bonus, isLoading: bonusLoading } = useGetUserBonusesQuery(
    user.id
  );
  const prefetchBonusHistory = usePrefetch("getBonusHistory");
  const { data: userActivity, isLoading: activityLoading } =
    useGetUserActivityQuery();
  const userStatus = STATUS[userActivity?.user_status];

  useEffect(() => {
    const handleGetOrders = async () => {
      try {
        await getOrders({ user_id: user.id }).unwrap();
      } catch (err) {}
    };
    handleGetOrders();
  }, [getOrders, user.id]);

  const handleShowSettings = () => {
    const settingsCard = document.querySelector(".settings-card");

    if (!showSettings.value) {
      showSettings.on();
      setTimeout(() => {
        animate.off();
      }, 200);
    }
    if (showSettings.value) {
      settingsCard.classList.add("close");
      settingsCard.addEventListener("animationend", () => {
        showSettings.off();
        animate.on();
      });
    }
  };

  const handleSignOut = async () => {
    try {
      location.state = { logout: true };
      await logout({ refresh: token.refresh }).unwrap();
      dispatch(clear());
    } catch (err) {}
  };

  return (
    <>
      {modal?.modalName === "activity" && userActivity && (
        <ActivityModal activity={userActivity} status={userStatus} />
      )}
      {modal?.modalName === "bonus" && (
        <BonusModal bonus={bonus} userId={user.id} />
      )}
      {modal?.modalName === "bonus-about" && <BonusAbout />}
      {modal?.modalName === "coupon-activation" && <CouponModal />}
      <main className="account-main pad" key={location.key}>
        <div className="container">
          <section className="account-user" aria-label="Пользователь">
            <div className="user-info">
              <h4>
                {userStatus?.icon || (
                  <div
                    style={{
                      height: "1.5rem",
                      width: "1.5rem",
                      background: "#D7DBE0",
                      borderRadius: ".5rem",
                    }}
                  />
                )}
                <b>{user.name}</b>
              </h4>
              <span>{user.email}</span>
            </div>
            <button
              className="btn-inline-icon account-controls"
              onClick={handleShowSettings}
            >
              <FiSettings />
              {tablet ? (mobile ? "" : "Настройки") : "Настройки аккаунта"}
            </button>
            <button
              className="btn-inline-icon account-controls"
              onClick={handleSignOut}
            >
              <FiLogOut />
              {mobile ? "" : "Выход"}
            </button>
          </section>
          {showSettings.value && (
            <AccountSettings
              animate={animate.value}
              handleShowSettings={handleShowSettings}
            />
          )}
          <section className="account-bonus" aria-label="Программа лояльности">
            <header className="account-bonus-header">
              <div
                className="activity-card card"
                onClick={() => dispatch(setIsModal({ modalName: "activity" }))}
              >
                <span className="title">Ваш статус:</span>
                {activityLoading ? (
                  <IconSpinner className="spinner" />
                ) : (
                  userActivity && (
                    <span className="activity-status">
                      <span>{userStatus?.icon}</span>
                      {userStatus?.name}
                    </span>
                  )
                )}
                <div className="ativity-coeff-container">
                  <span className="title">Индекс активности:</span>
                  <span className="activity-coeff">
                    {userActivity?.main_coef !== undefined ? (
                      parseFloat(userActivity.main_coef)
                    ) : (
                      <span style={{ color: "transparent" }}>0</span>
                    )}
                  </span>
                </div>
                <footer>
                  подробнее <BsArrowRight />
                </footer>
              </div>
              <div
                className="bonus-card"
                onClick={() => dispatch(setIsModal({ modalName: "bonus" }))}
                onMouseEnter={() => prefetchBonusHistory(user.id)}
              >
                <span className="title">Бонусные баллы:</span>
                <span className="count">
                  {bonusLoading ? (
                    <>
                      <IconSpinner className="spinner" />
                      <span style={{ opacity: "0" }}>0</span>
                    </>
                  ) : (
                    <>
                      {bonus?.toLocaleString("ru-RU")}
                      <IconBonus />
                    </>
                  )}
                </span>
                <footer>
                  подробнее <BsArrowRight />
                </footer>
              </div>
              <div className="bonus-links">
                <span
                  className="text-link"
                  data-theme="light"
                  onClick={() =>
                    dispatch(setIsModal({ modalName: "bonus-about" }))
                  }
                >
                  Как работает система лояльности?
                </span>
                <span
                  className="text-link"
                  data-theme="light"
                  onClick={() =>
                    dispatch(setIsModal({ modalName: "coupon-activation" }))
                  }
                >
                  Активировать подарочный купон
                </span>
              </div>
            </header>
          </section>
          <section className="account-orders" aria-label="История бронирований">
            <h5>История бронирований</h5>
            {orders?.length ? (
              <OrdersList orders={orders} />
            ) : (
              <div
                className={`${
                  !orders ? "empty-orders loading" : "empty-orders"
                }`}
              >
                <span>
                  {orders && "Вы еще не сделали ни одного бронирования"}
                </span>
              </div>
            )}
          </section>
        </div>
      </main>
    </>
  );
};

export default Account;
