import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Mutex } from "async-mutex";
import { clear } from "features/reservationSlice";
import { updateToken, signout } from "features/userSlice";
import { BASE_URL } from "helpers/constants";

const mutex = new Mutex();

const baseQuery = fetchBaseQuery({
  baseUrl: `${BASE_URL}/api/v1/`,
  prepareHeaders: (headers, { getState, endpoint }) => {
    const token = getState().user?.token?.access;
    if (token && endpoint !== "getFutureEvents") {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        const token = api.getState().user?.token?.refresh;

        const refreshResult = await baseQuery(
          {
            url: "account/refresh/",
            method: "POST",
            body: { refresh: token },
          },
          api,
          extraOptions
        );

        if (refreshResult.data) {
          api.dispatch(updateToken(refreshResult.data));
          result = await baseQuery(args, api, extraOptions);
        } else {
          api.dispatch(signout());
          api.dispatch(clear());
        }
      } finally {
        release();
      }
    } else {
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }
  return result;
};

export const api = createApi({
  reducerPath: "api",
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),
});

export const { usePrefetch } = api;
