import { useEffect } from "react";
import {
  RadioGroup,
  RadioGroupRoomType,
  RadioNumberGroup,
} from "components/inputs";
import { ReactComponent as IconRoomSingle } from "icons/icon-room-single.svg";
import { ReactComponent as IconRoomFull } from "icons/icon-room-full.svg";

import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import { useDispatch, useSelector } from "react-redux";
import { editSuit } from "features/reservationSlice";
import { selectIsLoading } from "features/appSlice";

const roomTypes = [
  {
    name: "Место в номере",
    icon: <IconRoomSingle />,
  },
  {
    name: "Номер целиком",
    icon: <IconRoomFull />,
  },
];

const PrimarySelects = ({ suit, eventId, suitId }) => {
  const suitCapacity = [];
  for (let i = suit.min_capacity; i <= suit.capacity; i++) {
    suitCapacity.push(i);
  }

  const isClubPackage = suit.reservation_type === 2;
  const isSingleRoomType =
    Array.isArray(suit.room_available_type) &&
    suit.room_available_type.length === 1;
  const isSingleGender =
    Array.isArray(suit.available_gender) && suit.available_gender.length === 1;

  const defaultRoomType = isSingleRoomType
    ? suit.room_available_type[0]
    : suit.options.type;
  const isPlaceInRoom = defaultRoomType === "Место в номере";

  const defaultQuantity =
    (isClubPackage && suit.options.quantity === 0) || isPlaceInRoom
      ? 1
      : suit.options.quantity;
  const defaultGender =
    isPlaceInRoom && isSingleGender
      ? suit.available_gender[0]
      : suit.options.gender;

  const selects = {
    ...suit.options,
    type: defaultRoomType,
    quantity: defaultQuantity,
    gender: defaultGender,
  };

  const dispatch = useDispatch();
  const loading = useSelector(selectIsLoading);

  const schema = Yup.object().shape({
    type: Yup.string()
      .required("Необходимо выбрать тип бронировния")
      .nullable(),
    quantity: Yup.number()
      .min(1, "Необходимо выбрать количество участников")
      .required()
      .nullable(),
    gender: Yup.string().when("type", {
      is: "Место в номере",
      then: Yup.string()
        .required("Необходимо выбрать пол участника")
        .nullable(),
      otherwise: Yup.string().notRequired(),
    }),
  });

  const validationSchema = { resolver: yupResolver(schema) };

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors, isValid },
  } = useForm({
    ...validationSchema,
    mode: "all",
    defaultValues: selects,
  });

  const data = useWatch({
    control: control,
  });

  useEffect(() => {
    dispatch(
      editSuit({
        eventId,
        suitId,
        selects: { ...data, valid: isValid },
      })
    );
  }, [dispatch, data, eventId, suitId, isValid, loading]);

  const onSubmit = () => {};

  return (
    <form
      className="primary-selects"
      onSubmit={handleSubmit(onSubmit)}
      data-hidden={isClubPackage || undefined}
    >
      <RadioGroupRoomType
        name="type"
        control={control}
        label="Выберите тип бронирования"
        setValue={setValue}
        options={roomTypes}
        available={suit.room_available_type}
        availableGender={suit.available_gender}
        error={errors.type?.message}
      />
      {selects.type === "Номер целиком" && (
        <RadioNumberGroup
          name="quantity"
          control={control}
          label="Выберите количество участников"
          option={selects.quantity}
          remaining={suit.remaining}
          arr={suitCapacity}
          error={errors.quantity?.message}
        />
      )}
      {selects.type === "Место в номере" && (
        <RadioGroup
          label="Укажите пол участника"
          option={selects.gender}
          {...register("gender")}
          setValue={setValue}
          available={suit.available_gender}
          arr={["Мужской", "Женский"]}
          error={errors.gender?.message}
        />
      )}
    </form>
  );
};

export default PrimarySelects;
