import { Navigate, useNavigate } from "react-router-dom";
import { VscArrowLeft } from "react-icons/vsc";
import { ReservationHeader } from "components";
import EventGroup from "./EventGroup";
import Summary from "./Summary";
import BalanceModal from "./BalanceModal";

import { useSelector, useDispatch } from "react-redux";
import {
  selectReservation,
  selectReservationObj,
} from "features/reservationSlice";
import { selectIsModal, setMessage } from "features/appSlice";

const Checkout = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const reservation = useSelector(selectReservation);
  const reservationObj = useSelector(selectReservationObj);
  const isModal = useSelector(selectIsModal);

  if (!reservation.length || !reservationObj.pay_price) {
    if (reservation.length && !reservationObj.pay_price) {
      dispatch(
        setMessage({
          type: "error",
          msg: "Что-то пошло не так, попробуйте позже",
        })
      );
    }
    return <Navigate to="/reservation" replace />;
  }

  return (
    <>
      {isModal && <BalanceModal />}
      <main className="checkout-main pad">
        <div className="container">
          <button
            className="btn-inline-icon btn-back"
            onClick={() => navigate("/reservation")}
          >
            <VscArrowLeft />
            Назад
          </button>
          <ReservationHeader step="3" />
          <div className="checkout-list-container card-list">
            <section className="checkout-list" aria-label="список бронирований">
              {reservation.map((el) => {
                return <EventGroup key={el.slug} event={el} />;
              })}
            </section>
            <Summary />
          </div>
        </div>
      </main>
    </>
  );
};

export default Checkout;
