import { configureStore } from "@reduxjs/toolkit";
import reservationReducer from "features/reservationSlice";
import userReducer from "features/userSlice";
import globalReducer from "features/appSlice";
import { api } from "./api";

import { saveState, loadState } from "./localStorage";
import throttle from "lodash/throttle";

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    reservation: reservationReducer,
    user: userReducer,
    global: globalReducer,
  },
  preloadedState: loadState(),
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware),
});

store.subscribe(
  throttle(() => {
    saveState({
      reservation: store.getState().reservation,
      user: store.getState().user,
    });
  }, 1000)
);
