import { clear } from "features/reservationSlice";
import { signout } from "features/userSlice";
import { api } from "./api";

const authApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAccount: builder.mutation({
      query: () => "account/",
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(api.endpoints.getReservation.initiate({ user_id: data.id }));
        } catch (err) {}
      },
    }),
    createAccount: builder.mutation({
      query: (credentials) => ({
        url: "account/registration/",
        method: "POST",
        body: credentials,
      }),
    }),
    confirm: builder.mutation({
      query: (args) => ({
        url: "account/registration/confirm/",
        method: "POST",
        body: args,
      }),
    }),
    updateAccount: builder.mutation({
      query: (args) => ({
        url: "account/",
        method: "PATCH",
        body: args,
      }),
    }),
    login: builder.mutation({
      query: (credentials) => ({
        url: "account/login/",
        method: "POST",
        body: credentials,
      }),
      async onQueryStarted(
        credentials,
        { getState, queryFulfilled, dispatch }
      ) {
        const { reservation } = getState();
        try {
          const { data } = await queryFulfilled;
          if (data && reservation.data.length) {
            dispatch(
              api.endpoints.updateReservation.initiate({
                user_id: data.user.id,
                reservation_uuid: reservation.id,
                reservation: reservation.data,
              })
            );
          }
        } catch (err) {}
      },
      invalidatesTags: ["Activity"],
    }),

    changePassword: builder.mutation({
      query: (args) => ({
        url: "account/change-password/",
        method: "PUT",
        body: args,
      }),
    }),
    remindPassword: builder.mutation({
      query: (phone) => ({
        url: "account/remind-password/",
        method: "POST",
        body: phone,
      }),
    }),
    remindPasswordCodeConfirm: builder.mutation({
      query: (args) => ({
        url: "account/remind-password/code-confirm/",
        method: "POST",
        body: args,
      }),
    }),
    remindPasswordChangePassword: builder.mutation({
      query: (args) => ({
        url: "account/remind-password/change-password/",
        method: "POST",
        body: args,
      }),
    }),

    sendEmailConfirm: builder.mutation({
      query: (args) => ({
        url: "account/send_email_confirmation/",
        method: "POST",
        body: args,
      }),
    }),

    sendVoiceCallConfirmation: builder.mutation({
      query: (args) => ({
        url: "userdata/send_voice_call_confirmation",
        method: "POST",
        body: { ...args, confirmation_type: 4 },
      }),
    }),
    resendVoiceCallConfirmation: builder.mutation({
      query: (args) => ({
        url: "userdata/resend_voice_call_confirmation",
        method: "POST",
        body: args,
      }),
    }),
    sendSmsConfirmation: builder.mutation({
      query: (args) => ({
        url: "userdata/send_sms_confirmation",
        method: "POST",
        body: args,
      }),
    }),
    resendSmsConfirmation: builder.mutation({
      query: (args) => ({
        url: "userdata/resend_sms_confirmation",
        method: "POST",
        body: args,
      }),
    }),

    refreshToken: builder.mutation({
      query: (args) => ({
        url: "account/refresh/",
        method: "POST",
        body: args,
      }),
    }),
    logout: builder.mutation({
      query: (token) => ({
        url: "account/logout/",
        method: "POST",
        body: token,
      }),
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
        } catch (err) {
          dispatch(clear());
          dispatch(signout());
        }
      },
    }),
  }),
});

export const {
  useGetAccountMutation,
  useLoginMutation,
  useCreateAccountMutation,
  useConfirmMutation,
  useUpdateAccountMutation,
  useChangePasswordMutation,
  useRemindPasswordMutation,
  useRemindPasswordCodeConfirmMutation,
  useRemindPasswordChangePasswordMutation,
  useSendEmailConfirmMutation,
  useRefreshTokenMutation,
  useLogoutMutation,

  useSendVoiceCallConfirmationMutation,
  useResendVoiceCallConfirmationMutation,
  useSendSmsConfirmationMutation,
  useResendSmsConfirmationMutation,
} = authApi;
