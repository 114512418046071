import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "hooks";
import Card from "./Card";
import DetailsCard from "./DetailsCard";

import { useDispatch, useSelector } from "react-redux";
import { selectReservation } from "features/reservationSlice";
import { setLastLocation } from "features/appSlice";

const List = () => {
  const tablet = useMediaQuery("tablet");
  const navigate = useNavigate();

  const reservation = useSelector(selectReservation);
  const dispatch = useDispatch();

  const handleClick = (slug) => {
    dispatch(setLastLocation("/reservation"));
    navigate(`/event/${slug}`);
  };

  return (
    <section
      className="reservation-list-container"
      aria-label="список бронирований"
    >
      <div className="reservation-list">
        {reservation.map((event, eventId) => {
          return (
            <div className="reservation-list-group" key={eventId}>
              <h4>{event.header || "Мероприятие"}</h4>
              {event.suits &&
                event.suits.length !== 0 &&
                event.suits.map((suit, suitId) => {
                  return (
                    <Card
                      key={suit.id}
                      eventId={eventId}
                      suitId={suitId}
                      event={event}
                      suit={suit}
                    />
                  );
                })}
              {!event.suits.some((el) => el.reservation_type === 2) &&
                tablet && (
                  <button
                    className="btn btn-outline"
                    onClick={() => handleClick(event.slug)}
                  >
                    + Добавить еще один номер
                  </button>
                )}
            </div>
          );
        })}
      </div>
      <DetailsCard />
    </section>
  );
};

export default List;
