const EventCountdown = ({ days, hours, minutes, seconds }) => {
  const addLeadingZero = (val) => (val >= 10 ? val : `0${val}`);

  return (
    <div className="countdown-container">
      <span className="title">Старт продаж</span>
      <div className="countdown">
        {days > 0 && (
          <div className="countdown-item">
            <span>{addLeadingZero(days)}</span>
            <span>дней</span>
          </div>
        )}
        <div className="countdown-item">
          <span>{addLeadingZero(hours)}</span>
          <span>часов</span>
        </div>
        <div className="countdown-item">
          <span>{addLeadingZero(minutes)}</span>
          <span>минут</span>
        </div>
        {days === 0 && (
          <div className="countdown-item">
            <span>{addLeadingZero(seconds)}</span>
            <span>секунд</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default EventCountdown;
