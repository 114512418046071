import { useLocation, useNavigate } from "react-router-dom";
import { InputField, InputPhone } from "components/inputs";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import "yup-phone-lite";

import { useCreateAccountMutation } from "app/authApi";

const fields = [
  {
    name: "name",
    type: "text",
    label: "Фамилия Имя",
  },
  {
    name: "email",
    type: "email",
    label: "Адрес электронной почты",
  },
  {
    name: "phone",
    type: "tel",
    label: "Телефон",
  },
  {
    name: "password",
    type: "password",
    label: "Пароль",
  },
  {
    name: "confirmPassword",
    type: "password",
    label: "Подтвердите пароль",
  },
];

const SignUpForm = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [createAccount] = useCreateAccountMutation();

  const schema = Yup.object().shape({
    name: Yup.string()
      .required("Поле не может быть пустым")
      .matches(/^[а-яА-Я-a-zA-ZЁё\s]+$/, "Недопустимые символы")
      .min(3, "Должно содержать не менее трех букв"),
    email: Yup.string()
      .required("Поле не может быть пустым")
      .email("Некорректный адрес электронной почты"),
    phone: Yup.string()
      .ensure()
      .required("Поле не может быть пустым")
      .phone("RU", "Некорректный номер телефона"),
    password: Yup.string()
      .required("Поле не может быть пустым")
      .min(8, "Пароль должен содержать не менее 8 символов"),
    confirmPassword: Yup.string()
      .required("Поле не может быть пустым")
      .oneOf([Yup.ref("password"), null], "Пароли не совпадают"),
  });
  const validationSchema = { resolver: yupResolver(schema) };

  const {
    register,
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm(validationSchema);

  // Submit form
  const onSubmit = async (data) => {
    const { email, password, confirmPassword, name, phone } = data;
    try {
      await createAccount({
        email,
        password,
        confirmPassword,
        firstName: name,
        phone,
      }).unwrap();
      navigate("/confirmation", { state: { from: location } });
    } catch (err) {
      if (err.data?.phone) {
        setError(
          "phone",
          {
            type: "manual",
            message: err.data.phone,
          },
          { shouldFocus: true }
        );
      }
      if (err.data?.email) {
        setError(
          "email",
          {
            type: "manual",
            message: err.data.email,
          },
          { shouldFocus: true }
        );
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="auth-form" noValidate>
      {fields.map((field, id) => {
        const { name, type, label } = field;
        if (name === "phone") {
          return (
            <InputPhone
              key={id}
              label={label}
              id={"signup-" + name + "-input"}
              type={type}
              name={name}
              control={control}
              error={errors[name]?.message}
            />
          );
        }
        return (
          <InputField
            key={id}
            label={label}
            id={"signup-" + name + "-input"}
            type={type}
            {...register(name)}
            error={errors[name]?.message}
          />
        );
      })}
      <button className="btn btn-primary" type="submit">
        Зарегистрироваться
      </button>
    </form>
  );
};

export default SignUpForm;
