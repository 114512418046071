import { useController } from "react-hook-form";

const RadioNumberGroup = ({
  name,
  control,
  label,
  arr,
  option,
  remaining,
  error,
  ...props
}) => {
  const { field } = useController({ name, control });

  const onChange = (e) => {
    field.onChange(Number(e.target.value));
  };

  return (
    <div className={error ? "radio-container error" : "radio-container"}>
      <strong>
        {label} {error && <span className="required-mark">*</span>}
      </strong>
      <div className="numbers-container" data-error={!!error || undefined}>
        {arr.map((el, id) => {
          return (
            <label
              className={`radio-btn-number ${option === el ? "active" : ""} ${
                remaining < el ? "disabled" : ""
              }`}
              title={remaining < el ? "Недоступно для этого номера" : undefined}
              key={id}
            >
              {el}
              <input
                {...{ ...field, onChange }}
                {...props}
                type="radio"
                value={el}
                disabled={remaining < el}
                checked={option === el}
              />
              <span className="checkmark"></span>
            </label>
          );
        })}
      </div>
      {error && <span className="error-msg">{error}</span>}
    </div>
  );
};

export default RadioNumberGroup;
