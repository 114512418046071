import { useState } from "react";
import { useNavigate } from "react-router";
import { Modal } from "components";
import { useCountDown } from "hooks";
import { useCloseModal } from "components/Modal";
import { InputField } from "components/inputs";
import { ReactComponent as IconSpinner } from "icons/icon-spinner.svg";
import { localePrice } from "helpers/locales";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import { useSelector, useDispatch } from "react-redux";
import {
  useConfirmInvoiceBalancePaymentMutation,
  useGetUserBalanceQuery,
  useSendConfirmationCodeMutation,
} from "app/userBalanceApi";
import { selectUser } from "features/userSlice";
import { selectIsModal, setMessage } from "features/appSlice";

const Form = ({ expiresAt, resendCode, invoiceId, closeModal }) => {
  const navigate = useNavigate();
  const { timeLeft } = useCountDown(expiresAt);

  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const [confirm, { isLoading }] = useConfirmInvoiceBalancePaymentMutation();

  const schema = Yup.object().shape({
    confirm: Yup.number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .required("Поле не может быть пустым"),
  });
  const validationSchema = { resolver: yupResolver(schema) };

  const {
    register,
    handleSubmit,
    setError,
    watch,
    formState: { errors },
  } = useForm(validationSchema);

  const isValid = !!watch("confirm");

  const handleResendCode = async () => {
    await resendCode();
  };

  const onConfirm = async (data) => {
    try {
      await confirm({
        invoice_uuid: invoiceId,
        user_id: user.id,
        sms_code: data.confirm,
      }).unwrap();
      closeModal();
      navigate("/checkout/success");
    } catch (err) {
      if (err.status === 400) {
        setError(
          "confirm",
          {
            type: "manual",
            message: "Неверный код подтверждения",
          },
          { shouldFocus: true }
        );
      } else {
        dispatch(
          setMessage({
            type: "error",
            msg: "Что-то пошло не так. Попробуйте позже",
          })
        );
      }
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onConfirm)}
      className="auth-form"
      autoComplete="off"
      noValidate
    >
      <p style={{ marginBottom: "1.5rem" }}>
        Для подтверждения операции введите код, отправленный на номер{" "}
        {user.phone}
      </p>
      <InputField
        label="Код подтверждения"
        id="confirm-phone-input"
        pattern="[0-9]*"
        type="number"
        autoFocus="on"
        {...register("confirm", { valueAsNumber: true })}
        error={errors.confirm?.message}
        helperLink={
          timeLeft > 0 ? (
            <span className="helper-text helper-text-dark">
              Повторная отправка кода возможна через {Math.floor(timeLeft)} сек.
            </span>
          ) : (
            <span className="btn-inline form-link" onClick={handleResendCode}>
              Отправить код еще раз
            </span>
          )
        }
      />
      <button
        className="btn btn-primary"
        data-disabled={!isValid || undefined}
        data-loading={isLoading || undefined}
        disabled={isLoading || undefined}
        type="submit"
        style={{ width: "100%", marginTop: "2rem" }}
      >
        {isLoading ? (
          <>
            <IconSpinner className="spinner" style={{ marginRight: ".5rem" }} />{" "}
            Завершение оплаты
          </>
        ) : (
          `Подтвердить и оплатить счет`
        )}
      </button>
    </form>
  );
};

const BalanceModal = () => {
  const [isSent, setIsSent] = useState(false);
  const [time, setTime] = useState("");
  const closeModal = useCloseModal();

  const user = useSelector(selectUser);
  const invoice = useSelector(selectIsModal);
  const [sendCode] = useSendConfirmationCodeMutation();
  const { data: userBalance, isLoading } = useGetUserBalanceQuery(user.id);

  const isSufficient = userBalance >= invoice.price;

  const handleSendCode = async () => {
    try {
      await sendCode({ user_pk: user.id }).unwrap();
      setTime(Date.now() + 30000);
    } catch (err) {}
  };

  const onNext = async () => {
    await handleSendCode();
    setIsSent(true);
  };

  if (isLoading) return null;

  return (
    <Modal name="balance">
      <h4>Оплата с личного счета</h4>
      {!isSufficient && (
        <p>
          На вашем личном счету не хватает денежных средств для оплаты. Вы
          можете пополнить личный счет в личном кабинете клуба{" "}
          <a
            href="http://lk.xbadm.ru/"
            rel="noreferrer"
            target="_blank"
            className="text-link"
            onClick={closeModal}
          >
            lk.xbadm.ru
          </a>
        </p>
      )}
      <ul>
        <li>
          <p>Баланс личного счета:</p>
          {userBalance === undefined ? (
            <p>Загрузка...</p>
          ) : (
            <b>{localePrice(userBalance)}</b>
          )}
        </li>
        <li>
          <p>К оплате:</p>
          <b>{localePrice(invoice.price)}</b>
        </li>
      </ul>
      {isSufficient ? (
        isSent ? (
          <Form
            expiresAt={time}
            resendCode={handleSendCode}
            userId={user.id}
            invoiceId={invoice.uuid}
            closeModal={closeModal}
          />
        ) : (
          <button className="btn btn-primary" onClick={onNext}>
            Далее
          </button>
        )
      ) : (
        <button className="btn btn-outline" onClick={closeModal}>
          Закрыть окно
        </button>
      )}
    </Modal>
  );
};

export default BalanceModal;
