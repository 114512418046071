import { useNavigate, useLocation, Navigate, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

import { selectTemp, updateToken, updateTempUser } from "features/userSlice";
import { useUpdateReservationMutation } from "app/eventsApi";
import { setMessage } from "features/appSlice";
import {
  useConfirmMutation,
  useGetAccountMutation,
  useResendSmsConfirmationMutation,
  useResendVoiceCallConfirmationMutation,
  useSendSmsConfirmationMutation,
} from "app/authApi";
import {
  selectReservation,
  selectReservationId,
} from "features/reservationSlice";

import { InputField } from "components/inputs";
import { useCountDown } from "hooks";

const PhoneConfirm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const from = location.state?.from?.pathname || "/";

  const tempUser = useSelector(selectTemp);
  const reservation = useSelector(selectReservation);
  const reservation_uuid = useSelector(selectReservationId);
  const [resendCallConfirmation] = useResendVoiceCallConfirmationMutation();
  const [sendMessageConfirmation] = useSendSmsConfirmationMutation();
  const [resendMessageConfirmation] = useResendSmsConfirmationMutation();
  const [confirm] = useConfirmMutation();
  const [getAccount] = useGetAccountMutation();
  const [updateReservation] = useUpdateReservationMutation();

  const { timeLeft } = useCountDown(tempUser?.expired_at);

  const schema = Yup.object().shape({
    confirm: Yup.string().required("Поле не может быть пустым"),
  });
  const validationSchema = { resolver: yupResolver(schema) };

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm(validationSchema);

  // Confirm phone number
  const onConfirm = async (data) => {
    try {
      const response = await confirm({
        ...tempUser,
        code: data.confirm,
      }).unwrap();
      dispatch(updateToken(response.token));
      const user = await getAccount().unwrap();
      if (reservation.length) {
        try {
          await updateReservation({
            user_id: user.id,
            reservation_uuid,
            reservation,
          }).unwrap();
        } catch (err) {}
      }
      navigate(from, { replace: true });
    } catch (err) {
      setError(
        "confirm",
        {
          type: "manual",
          message: "Неверный код подтверждения",
        },
        { shouldFocus: true }
      );
    }
  };

  // Resend phone code
  const resendCode = async () => {
    try {
      let res = {};

      if (tempUser.code_delivery === 2) {
        if (tempUser.available_attempts_amount > 0) {
          res = await resendCallConfirmation({
            confirm_token_hash: tempUser.token_hash,
          }).unwrap();
        } else {
          res = await sendMessageConfirmation({
            phone: tempUser.phone,
            confirmation_type: 4,
          }).unwrap();
        }
      } else {
        res = await resendMessageConfirmation({
          confirm_token_hash: tempUser.token_hash,
        }).unwrap();
      }

      dispatch(
        updateTempUser({ template_user_id: tempUser.template_user_id, ...res })
      );
      dispatch(
        setMessage({
          type: "success",
          msg: "Код подтверждения отправлен повторно",
        })
      );
    } catch (err) {
      dispatch(
        setMessage({
          type: "error",
          msg:
            err?.data?.error ||
            "Время повторной отправки не истекло. Попробуйте позже",
        })
      );
    }
  };

  if (!tempUser && !location.state) {
    return <Navigate to="/account" replace />;
  }

  return (
    <main className="auth-main pad">
      <div className="container">
        <article className="card auth-card" aria-label="авторизация">
          <header className="auth-header">
            <h4>Подтвердите номер телефона</h4>
            <p>
              У вас уже есть аккаунт?{" "}
              <Link to="/login" className="btn-inline">
                Вход
              </Link>
            </p>
          </header>
          <form
            onSubmit={handleSubmit(onConfirm)}
            className="auth-form"
            autoComplete="off"
          >
            <small className="info-msg">
              {tempUser?.code_delivery === 2
                ? `Пожалуйста, введите последние 4 цифры номера телефона, с которого поступит звонок на указанный вами номер ${
                    tempUser && tempUser.phone
                  }`
                : `Пожалуйста, введите код подтверждения отправленный на указанный вами номер ${
                    tempUser && tempUser.phone
                  }`}
            </small>
            <InputField
              label="Код подтверждения"
              id="confirm-phone-input"
              pattern="[0-9]*"
              autoFocus="on"
              {...register("confirm")}
              error={errors.confirm?.message}
              helperLink={
                timeLeft > 0 ? (
                  <span className="helper-text helper-text-dark">
                    Повторная отправка возможна через {Math.floor(timeLeft)}{" "}
                    сек.
                  </span>
                ) : tempUser?.code_delivery === 2 ? (
                  tempUser?.available_attempts_amount > 0 ? (
                    <span className="btn-inline form-link" onClick={resendCode}>
                      Позвонить еще раз
                    </span>
                  ) : (
                    <span
                      className="helper-text-dark"
                      style={{ fontSize: ".75rem" }}
                    >
                      Звонок не поступил?{" "}
                      <span
                        className="btn-inline form-link"
                        onClick={resendCode}
                      >
                        Отправить SMS-код
                      </span>
                    </span>
                  )
                ) : (
                  <span className="btn-inline form-link" onClick={resendCode}>
                    Отправить код еще раз
                  </span>
                )
              }
            />
            <button className="btn btn-primary" type="submit">
              Подтвердить
            </button>
          </form>
        </article>
      </div>
    </main>
  );
};

export default PhoneConfirm;
