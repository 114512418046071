import { useState } from "react";
import { VscArrowDown } from "react-icons/vsc";
import { Tinkoff } from "components";
import { loadTinkoff } from "helpers";
import { localePrice } from "helpers/locales";

import { useDispatch } from "react-redux";
import { setIsModal } from "features/appSlice";

const Summary = ({ order }) => {
  const [showDiscountList, setShowDiscountList] = useState(false);

  const isSuccess = order.status !== "Не оплачено";

  const dispatch = useDispatch();

  const checkoutSubmit = (e) => {
    e.preventDefault();
    loadTinkoff(() => {
      window.pay(e.target);
    });
    return false;
  };

  return (
    <section className="summary-container" aria-label="Сводка бронирования">
      <div className="summary-card card">
        <div className="summary-event-group">
          <h6>{order.header}</h6>
          <ul className="summary-table">
            <li className="table-header">
              <small>Номер / Пакет</small>
              <small>Цена</small>
            </li>
            {order.suits.map((item) => {
              return (
                <li key={item.id}>
                  <p>{item.name}</p>
                  <span className="summary-price">
                    {localePrice(item.price)}
                  </span>
                </li>
              );
            })}
          </ul>
          <div className="summary-subtotal">
            <div className="summary-subtotal-row">
              <span className="summary-price">
                <small>Итого:</small>
                {order.discount
                  ? localePrice(order.discount.price)
                  : localePrice(order.price)}
              </span>
              {order.discount && (
                <div
                  className="summary-price-discount"
                  data-show={showDiscountList || undefined}
                >
                  <div
                    className="summary-price-discount-row"
                    onClick={() => setShowDiscountList(!showDiscountList)}
                  >
                    <del>{localePrice(order.discount?.amount)}</del>
                    <span className="summary-price-badge">
                      {order.discount?.name}%
                    </span>
                    <VscArrowDown />
                  </div>
                  <ul className="discount-list">
                    {order.discount?.list?.map((discount, id) => (
                      <li key={id}>
                        <small>{discount.text}</small>
                        <span className="summary-price">{discount.name}%</span>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
            {order.bonus.applied && (
              <div className="summary-subtotal-row">
                <span className="summary-price paid">
                  <small>Оплачено бонусами:</small>
                  {localePrice(order.bonus.bonus_point_to_use)}
                </span>
              </div>
            )}
            <div className="summary-subtotal-row">
              <span
                className={
                  isSuccess
                    ? "summary-price paid"
                    : "summary-price paid failure"
                }
              >
                <small>Оплачено:</small>
                {order.pay_price ? `${localePrice(order.pay_price)}` : "0 ₽"}
              </span>
            </div>
          </div>
        </div>
        <div className="summary-invoices">
          <h5>Счета</h5>
          <ul className="summary-table">
            <li className="table-header">
              <small>Название</small>
              <small>Цена</small>
              <small>Статус</small>
            </li>
            {order.invoices.map((item) => {
              return (
                <li key={item.id}>
                  <p>{item.name}</p>
                  <span className="summary-price">
                    {localePrice(item.price)}
                  </span>
                  <span className="payment-status">
                    <span className="payment-status-label">
                      {item.pay ? (
                        <>
                          Оплачено
                          <br />
                          <i>{item.payment_method.toLowerCase()}</i>
                        </>
                      ) : (
                        "Не оплачено"
                      )}
                    </span>
                    <span
                      className={`payment-status-icon ${
                        item.pay ? "success" : "failure"
                      }`}
                    ></span>
                  </span>
                  {!item.pay && (
                    <>
                      <form
                        name="TinkoffPayForm"
                        onSubmit={(e) => checkoutSubmit(e)}
                      >
                        <Tinkoff values={item.payment_data} />
                        <button
                          className="btn btn-primary small"
                          type="submit"
                          value="Оплатить"
                        >
                          Оплатить
                        </button>
                      </form>
                      <div className="balance-container">
                        <button
                          className="btn-balance"
                          onClick={() =>
                            dispatch(
                              setIsModal({ modalName: "balance", ...item })
                            )
                          }
                        >
                          Оплатить с личного счета
                        </button>
                      </div>
                    </>
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Summary;
