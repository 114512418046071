import { Modal } from "components";
import { useCloseModal } from "components/Modal";

const BonusAbout = () => {
  const handleClose = useCloseModal();

  return (
    <Modal name="bonus-about">
      <header>
        <h4>О программе лояльности</h4>
        <p>
          Дорогие участники проекта Khimki BC Events. Рады сообщить вам, что за
          каждое посещение мероприятия вам начисляются бонусы. Один бонусный
          балл дает право на скидку в один рубль.
        </p>
      </header>
      <article className="article">
        <h6>Как накопить?</h6>
        <p>
          Участвуйте в выездах, спортивных сборах, накапливайте баллы! Бонусы по
          посещенным вами мероприятиям будут начислены автоматически после их
          завершения.
        </p>
      </article>
      <article className="article">
        <h6>Как потратить?</h6>
        <p>
          Подберите интересующее вас мероприятие, оформите бронь, и перед
          оплатой укажите "Оплатить бонусами". Сообщаем, что вы можете оплатить
          бонусными баллами не более 30% вашего заказа.
        </p>
      </article>
      <article className="article">
        <h6>Баллы за мероприятия 01.01.2020-10.05.2022</h6>
        <p>
          Вы можете начислить баллы за мероприятия за указанный период. Нажмите
          на свои бонусные баллы и воспользуйтесь кнопкой начисления, произойдет
          поиск по наличию бонусов, "привязанных" к вашему номеру телефона.
        </p>
      </article>
      <article className="article">
        <h6>Не начислили бонусы? Что делать?</h6>
        <p>
          Напишите нам на почту{" "}
          <a href="mailto:events@xbadm.ru" className="btn-inline">
            events@xbadm.ru
          </a>{" "}
          с указанием дат мероприятий за которые вы хотите получить бонусы, мы
          постараемся ответить вам как можно скорее.
        </p>
      </article>
      <footer>
        <p>Спасибо, что вы с нами.</p>
      </footer>
      <button className="btn btn-outline" onClick={handleClose}>
        Закрыть окно
      </button>
    </Modal>
  );
};

export default BonusAbout;
