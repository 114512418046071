import { useEffect } from "react";
import { FaGift } from "react-icons/fa";
import { SuitInfo } from "components";
import { ReactComponent as IconSpinner } from "icons/icon-spinner.svg";
import { InputField } from "components/inputs";
import { ReactComponent as IconWarning } from "icons/icon-warning.svg";
import { useNavigate } from "react-router-dom";
import { localePrice } from "helpers/locales";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import { useDispatch, useSelector } from "react-redux";
import { selectReservationId } from "features/reservationSlice";
import { selectUser } from "features/userSlice";
import {
  useAddPromoCodeMutation,
  useDeletePromoCodeMutation,
  useGetReservationMutation,
  useUpdatePaymentMutation,
} from "app/eventsApi";
import { setMessage } from "features/appSlice";

const EventGroup = ({ event }) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [updatePayment] = useUpdatePaymentMutation();
  const [addPromoCode, { isLoading: isSubmitting }] = useAddPromoCodeMutation();
  const [deletePromoCode, { isLoading: isDeleting }] =
    useDeletePromoCodeMutation();
  const [getReservation] = useGetReservationMutation();
  const user = useSelector(selectUser);
  const reservation_uuid = useSelector(selectReservationId);

  const isPrepay = event.price.find((el) => el.is_selected).prepay;

  const schema = Yup.object().shape({
    promoCode: Yup.string().required("Поле не может быть пустым"),
  });

  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    reset,
    watch,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema), reValidateMode: undefined });

  const isValid = watch("promoCode") ? true : false;

  useEffect(() => {
    if (errors.promoCode) {
      setTimeout(() => {
        clearErrors();
      }, 3000);
    }
  }, [errors.promoCode, clearErrors]);

  const onSubmit = async (data) => {
    try {
      await addPromoCode({
        promo_code: data.promoCode,
        event_id: event.event_id,
        user_reservation_uuid: event.user_reservation_uuid,
      }).unwrap();
      await getReservation({
        user_id: user.id,
      }).unwrap();
      dispatch(
        setMessage({ type: "success", msg: "Промокод был успешно применен" })
      );
      reset();
    } catch (err) {
      setError("promoCode", {
        type: "manual",
        message: err.data?.error || "Неверный промокод",
      });
    }
  };

  const handleDeletePromo = async () => {
    try {
      await deletePromoCode({
        promo_code: event.promo_code.name,
        event_id: event.event_id,
        user_reservation_uuid: event.user_reservation_uuid,
      }).unwrap();
      await getReservation({
        user_id: user.id,
      }).unwrap();
    } catch (err) {}
  };

  const handleSelect = async () => {
    try {
      await updatePayment({
        user_id: user.id,
        reservation_uuid,
        event_id: event.event_id,
        price: {
          ...event.price.find((el) => !el.is_selected),
          is_selected: true,
        },
      }).unwrap();
      await getReservation({
        user_id: user.id,
      }).unwrap();
    } catch (err) {
      dispatch(
        setMessage({
          type: "error",
          msg: "Бронирование не найдено",
        })
      );
      navigate("/reservation", { replace: true });
    }
  };

  return (
    <div className="checkout-list-group">
      <article
        className="card card-container"
        aria-label="Информация о бронировании"
      >
        <h5 className="card-event-name">{event.header}</h5>
        {event.suits &&
          event.suits.map((item) => {
            return (
              <SuitInfo
                key={item.id}
                event={event}
                suit={item}
                editable={true}
              />
            );
          })}
        <div className="checkout-payment">
          <h5>Варианты оплаты</h5>
          <div className="payment-select">
            {event.price.map((el) => {
              return (
                <button
                  key={el.name}
                  className={
                    el.is_selected
                      ? "btn btn-payment"
                      : "btn btn-payment active"
                  }
                  disabled={el.is_selected}
                  onClick={handleSelect}
                >
                  <span className="payment-radio"></span>
                  <span className="payment-label">
                    {el.name} {el.prepay_amount && `${el.prepay_amount}%`}
                  </span>
                  <div className="payment-price-container">
                    <span className="payment-price">
                      {el.discount && !el.prepay
                        ? localePrice(el.discount.price)
                        : localePrice(el.price)}
                    </span>
                    {el.discount && !el.prepay && (
                      <span className="full-price">
                        {localePrice(el.discount.amount)}
                      </span>
                    )}
                  </div>
                </button>
              );
            })}
            {isPrepay && (
              <small className="warning">
                <IconWarning />
                <span>
                  При частичной оплате бронирования вы отказываетесь от скидки,
                  предусмотренной за полную оплату
                </span>
              </small>
            )}
          </div>
        </div>
        <form className="checkout-promo" onSubmit={handleSubmit(onSubmit)}>
          <h5>Промокод</h5>
          {event.promo_code ? (
            <div className="checkout-promo-success">
              <div className="checkout-promo-success-text">
                <FaGift />
                <span>
                  {event.promo_code.name}: скидка {event.promo_code.discount}%
                </span>
              </div>
              <button
                type="button"
                className="btn-upper"
                onClick={handleDeletePromo}
                data-delete
              >
                {isDeleting ? <IconSpinner className="spinner" /> : "Удалить"}
              </button>
            </div>
          ) : (
            <InputField
              autoComplete="off"
              placeholder="Введите промокод"
              {...register("promoCode")}
              error={errors.promoCode?.message}
            >
              <button
                type="submit"
                className="btn-upper"
                disabled={!isValid || undefined}
              >
                {isSubmitting ? (
                  <IconSpinner className="spinner" />
                ) : (
                  "Добавить"
                )}
              </button>
            </InputField>
          )}
        </form>
      </article>
    </div>
  );
};

export default EventGroup;
