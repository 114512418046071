import { BiArrowBack } from "react-icons/bi";
import { formatPhoneNumber } from "react-phone-number-input";
import { InputField } from "components/inputs";

import { useSelector } from "react-redux";
import { selectUser } from "features/userSlice";

const UpdatePhone = ({ currentSetting, setCurrentSetting, handleClose }) => {
  const { title } = currentSetting;

  const user = useSelector(selectUser);

  return (
    <section className="card settings-card" aria-label="Настройки аккаунта">
      <header className="settings-header">
        <h5
          className="settings-header-back"
          onClick={() => setCurrentSetting(null)}
        >
          <BiArrowBack />
          Изменить {title}
        </h5>
        <span className="btn-inline settings-close" onClick={handleClose}>
          Закрыть
        </span>
      </header>
      <form className="setting-form">
        <InputField
          label="Телефон"
          id="update-phone-input"
          type="phone"
          name="phone"
          disabled
          defaultValue={formatPhoneNumber(user.phone)}
          helperText={
            <span className="helper-text-dark">
              Для изменения номера телефона, пожалуйста, отправьте нам письмо на
              электронную почту{" "}
              <a href="mailto:info@xbadm.com" className="btn-inline">
                info@xbadm.com
              </a>
            </span>
          }
        />
        <div className="setting-form-controls">
          <button
            className="btn btn-outline"
            type="reset"
            onClick={() => setCurrentSetting(null)}
          >
            Отмена
          </button>
        </div>
      </form>
    </section>
  );
};

export default UpdatePhone;
