import { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { FaRegUser } from "react-icons/fa";
import { useMediaQuery, useOutsideClick } from "hooks";
import { ReactComponent as Logo } from "icons/logo.svg";
import { ReactComponent as IconCart } from "icons/icon-cart.svg";

import { useSelector } from "react-redux";
import { selectReservationCount } from "features/reservationSlice";
import { selectUser } from "features/userSlice";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const mobile = useMediaQuery("mobile");
  const headerRef = useRef(null);
  const location = useLocation();

  const reservationCount = useSelector(selectReservationCount);
  const user = useSelector(selectUser);

  const doc = document.documentElement;

  const handleClose = () => {
    setIsMenuOpen(false);
  };

  useEffect(() => {
    !mobile && isMenuOpen && handleClose();
  }, [mobile, isMenuOpen]);

  useEffect(() => {
    if (isMenuOpen) {
      doc.style.overflow = "hidden";
    } else {
      doc.removeAttribute("style");
    }
  }, [isMenuOpen, doc]);

  useOutsideClick(headerRef, handleClose);

  return (
    <header className={isMenuOpen ? "header active" : "header"}>
      <div className="header-wrapper pad" ref={headerRef}>
        <div className="container">
          <Link
            to="/"
            aria-label="Логотип khimkibc.events главная страница"
            className="logo"
            onClick={handleClose}
          >
            <Logo />
          </Link>
          <nav className="header-nav">
            <Link
              to="/reservation"
              aria-label="Корзина"
              className={
                reservationCount > 0
                  ? "reservation-link active"
                  : "reservation-link"
              }
              onClick={handleClose}
            >
              Корзина{" "}
              <span className="reservation-number">{reservationCount}</span>
            </Link>
            {user ? (
              mobile ? (
                <Link
                  to="/account"
                  aria-label="личный кабинет"
                  className="header-user"
                  onClick={handleClose}
                >
                  Личный кабинет
                </Link>
              ) : (
                <Link
                  to="/account"
                  aria-label="личный кабинет"
                  className="header-user"
                  onClick={handleClose}
                >
                  <FaRegUser />
                  {user.name}
                </Link>
              )
            ) : (
              <>
                <Link
                  to="/login"
                  state={{ from: location }}
                  aria-label="вход"
                  className="login-link"
                  onClick={handleClose}
                >
                  Вход
                </Link>
                <Link
                  to="/signup"
                  state={{ from: location }}
                  aria-label="регистрация"
                  className="signup-link"
                  onClick={handleClose}
                >
                  Регистрация
                </Link>
              </>
            )}
            {mobile && (
              <Link to="/contact" className="header-user" onClick={handleClose}>
                Контакты
              </Link>
            )}
          </nav>
          {mobile && (
            <div className="mobile-controls">
              <Link
                to="/reservation"
                className="shopping-cart"
                aria-label="Корзина"
                data-active={reservationCount > 0 || undefined}
                onClick={handleClose}
              >
                <IconCart />
                <span className="reservation-number">{reservationCount}</span>
              </Link>
              <div
                className={isMenuOpen ? "hamburger active" : "hamburger"}
                onClick={() => setIsMenuOpen((p) => (p = !p))}
              >
                <div className="hamburger-icon"></div>
              </div>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
