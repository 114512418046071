import { useNavigate, useLocation } from "react-router-dom";
import { useBool } from "hooks";
import { PasswordRecovery } from "components";
import LogInForm from "./LogInForm";
import SignUpForm from "./SignUpForm";

const AuthCard = ({ isSignup, from }) => {
  const isRegistration = useBool(isSignup);
  const forgotPassword = useBool();
  const location = useLocation();
  const navigate = useNavigate();

  const handleAuthChange = () => {
    isRegistration.toggle();

    if (location.pathname === "/signup") {
      return navigate("/login");
    }
    if (location.pathname === "/login") {
      return navigate("/signup");
    }
  };

  if (forgotPassword.value)
    return <PasswordRecovery forgotPassword={forgotPassword} />;

  if (isRegistration.value)
    return (
      <article className="card auth-card" aria-label="авторизация">
        <header className="auth-header">
          <h4>Регистрация</h4>
          <p>
            У вас уже есть аккаунт?{" "}
            <span className="btn-inline" onClick={handleAuthChange}>
              Вход
            </span>
          </p>
        </header>
        <SignUpForm />
      </article>
    );

  return (
    <article className="card auth-card" aria-label="авторизация">
      <header className="auth-header">
        <h4>Вход</h4>
        <p>
          У вас еще нет аккаунта?{" "}
          <span className="btn-inline" onClick={handleAuthChange}>
            Регистрация
          </span>
        </p>
      </header>
      <LogInForm from={from} forgotPassword={forgotPassword} />
    </article>
  );
};

export default AuthCard;
