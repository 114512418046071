import { BiArrowBack } from "react-icons/bi";
import { InputField } from "components/inputs";
import { ReactComponent as IconWarning } from "icons/icon-warning.svg";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "features/userSlice";
import { setMessage } from "features/appSlice";
import {
  useGetAccountMutation,
  useSendEmailConfirmMutation,
  useUpdateAccountMutation,
} from "app/authApi";

const UpdateEmail = ({ currentSetting, setCurrentSetting, handleClose }) => {
  const { label, name, title, type, value } = currentSetting;

  const [updateAccount] = useUpdateAccountMutation();
  const [getAccount] = useGetAccountMutation();
  const [sendEmailConfirm] = useSendEmailConfirmMutation();
  const {
    email_confirmed: isConfirmed,
    id: user_id,
    email,
  } = useSelector(selectUser);
  const dispatch = useDispatch();

  const schema = Yup.object().shape({
    email: Yup.string()
      .required("Поле не может быть пустым")
      .email("Некорректный адрес электронной почты"),
  });
  const validationSchema = { resolver: yupResolver(schema) };

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm(validationSchema);

  const onSubmit = async (data) => {
    if (value === data[name]) {
      return setCurrentSetting(null);
    }

    try {
      await updateAccount({ email: data[name] }).unwrap();
      getAccount();
      setCurrentSetting(null);
      dispatch(
        setMessage({
          type: "success",
          msg: "Адрес электронной почты успешно изменен. Мы отправили вам письмо c подтверждением",
        })
      );
    } catch (err) {
      setError(
        "email",
        {
          type: "manual",
          message: "Пользователь с такой электронной почтой уже существует",
        },
        { shouldFocus: true }
      );
    }
  };

  const handleSendEmailConfirm = async () => {
    try {
      await sendEmailConfirm({ email, user_id }).unwrap();
      dispatch(
        setMessage({
          type: "success",
          msg: "Письмо с подтверждением адреса электронной почты успешно отправлено",
        })
      );
      setCurrentSetting(null);
    } catch (err) {
      dispatch(
        setMessage({
          type: "error",
          msg: "Время повторной отправки не истекло. Попробуйте позже",
        })
      );
    }
  };

  const notConfirmed = () => (
    <span className="not-confirmed">
      <IconWarning />{" "}
      <span>
        Адрес электронной почты не подтвержден.{" "}
        <span className="btn-inline" onClick={handleSendEmailConfirm}>
          Отправить письмо с подтверждением повторно
        </span>
      </span>
    </span>
  );

  return (
    <section className="card settings-card" aria-label="Настройки аккаунта">
      <header className="settings-header">
        <h5
          className="settings-header-back"
          onClick={() => setCurrentSetting(null)}
        >
          <BiArrowBack />
          Изменить {title}
        </h5>
        <span className="btn-inline settings-close" onClick={handleClose}>
          Закрыть
        </span>
      </header>
      <form
        className="setting-form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <InputField
          label={label}
          id={`change-${name}-input`}
          type={type}
          defaultValue={value}
          helperText={!isConfirmed && notConfirmed()}
          {...register(name)}
          error={errors[name]?.message}
        />
        <div className="setting-form-controls">
          <button
            className="btn btn-outline"
            type="reset"
            onClick={() => setCurrentSetting(null)}
          >
            Отмена
          </button>
          <button className="btn btn-primary" type="submit">
            Сохранить
          </button>
        </div>
      </form>
    </section>
  );
};

export default UpdateEmail;
