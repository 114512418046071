import { useEffect, useRef } from "react";
import { FaRegTrashAlt } from "react-icons/fa";
import { FiUserPlus } from "react-icons/fi";
import { useLocation, useNavigate } from "react-router-dom";
import ParticipantForm from "./ParticipantForm";
import PrimarySelects from "./PrimarySelects";
import { BASE_URL } from "helpers/constants";

import { useDispatch, useSelector } from "react-redux";
import { useDeleteSuitMutation } from "app/eventsApi";
import {
  remove,
  selectReservationId,
  selectReservationCount,
  editSuit,
} from "features/reservationSlice";

const Card = ({ suit, event, eventId, suitId }) => {
  const reservationCardRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();

  const selects = suit.options;
  const reservationCount = useSelector(selectReservationCount);
  const [deleteSuit] = useDeleteSuitMutation();
  const reservationId = useSelector(selectReservationId);
  const dispatch = useDispatch();

  const showAddParticipantButton =
    suit.reservation_type === 2 ||
    (selects.type === "Номер целиком" &&
      selects.quantity > 0 &&
      selects.quantity < suit.capacity &&
      selects.quantity < suit.remaining);

  // Scroll to suit being edited
  useEffect(() => {
    if (location.state === +reservationCardRef.current.id) {
      reservationCardRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [location]);

  // Add participant
  const handleAddParticipant = () => {
    dispatch(
      editSuit({
        eventId,
        suitId,
        selects: { ...suit.options, quantity: suit.options.quantity + 1 },
      })
    );
  };

  // Delete suit function
  const handleDelete = async () => {
    try {
      await deleteSuit({
        reservation_uuid: reservationId,
        event_room_id: suit.id,
      });
      reservationCardRef.current.className += ` deleting`;
      if (reservationCount > 1) {
        reservationCardRef.current.addEventListener("animationend", () =>
          dispatch(remove({ eventId, suitId }))
        );
      } else {
        // window.scroll({ top: 0 });
        dispatch(remove({ eventId, suitId }));
        navigate(`/event/${event.slug}`);
      }
    } catch (err) {}
  };

  return (
    <article
      className="card card-container reservation-card"
      id={suit.id}
      ref={reservationCardRef}
      aria-label="карточка бронирования"
      key={suit.participants.length}
    >
      <header className="reservation-card-header card-header">
        <div className="reservation-card-img card-img">
          <img src={`${BASE_URL}${suit.image}`} alt="img" />
        </div>
        <h5 className="flex-1">{suit.name}</h5>
        <button
          className="btn-inline-icon btn-delete"
          onClick={handleDelete}
          aria-label="удалить бронирование"
        >
          <FaRegTrashAlt />
          <span>Удалить</span>
        </button>
      </header>
      <PrimarySelects suit={suit} eventId={eventId} suitId={suitId} />
      {suit.participants.length > 0 &&
        suit.participants.map((participant, participantId) => {
          if (
            (suit.participants.length === 1 &&
              selects.type === "Номер целиком") ||
            selects.gender
          ) {
            return (
              <ParticipantForm
                key={participantId}
                eventId={eventId}
                suitId={suitId}
                participantId={participantId}
                participant={participant}
                event={event}
                suit={suit}
              />
            );
          }
          if (suit.participants.length > 1) {
            return (
              <ParticipantForm
                key={participantId}
                eventId={eventId}
                suitId={suitId}
                participantId={participantId}
                participant={participant}
                participantNumber={participantId + 1}
                event={event}
                suit={suit}
              />
            );
          }
          return "";
        })}
      {showAddParticipantButton && (
        <div className="add-participant">
          <button
            className="btn btn-outline"
            type="button"
            onClick={handleAddParticipant}
          >
            <FiUserPlus /> Добавить участника
          </button>
        </div>
      )}
    </article>
  );
};

export default Card;
