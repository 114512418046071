import { useMediaQuery } from "hooks";

const UpcommingEvents = ({ data }) => {
  const mobile = useMediaQuery("mobile");
  const removeOneDay = (date) => {
    date.setDate(date.getDate() - 1);
    return date;
  };

  return (
    <section className="upcomming">
      <h4>Планируемые мероприятия</h4>
      <div className="list">
        {data.map((el, id) => (
          <div key={id} className="card">
            <h6>{el.summary}</h6>
            <p>
              {new Date(el.dateStart).toLocaleString("ru-RU", {
                day: "numeric",
              })}{" "}
              –{" "}
              {removeOneDay(new Date(el.dateEnd)).toLocaleString("ru-RU", {
                month: mobile ? "short" : "long",
                day: "numeric",
                year: "numeric",
              })}
            </p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default UpcommingEvents;
