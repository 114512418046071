import { useEffect, useState } from "react";

const useCountDown = (target, ms = 1000) => {
  const moscowTime = () =>
    Date.now() + new Date().getTimezoneOffset() * 60000 + 3600000 * 3;

  const [time, setTime] = useState(0);

  useEffect(() => {
    setTime(new Date(target).getTime() - moscowTime());
    const interval = setInterval(countDownUntilZero, ms);

    function countDownUntilZero() {
      setTime((p) => {
        if (p < 1000 && p !== 0) {
          clearInterval(interval);
          return 0;
        }

        return new Date(target).getTime() - moscowTime();
      });
    }

    return () => clearInterval(interval);
  }, [ms, target]);

  const timeLeft = Math.floor(time / 1000);
  const days = Math.floor(time / (1000 * 60 * 60 * 24));
  const hours = Math.floor((time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((time % (1000 * 60)) / 1000);

  return { timeLeft, days, hours, minutes, seconds };
};

export default useCountDown;
