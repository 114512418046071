const Payment = () => {
  return (
    <main className="text-main pad">
      <div className="container">
        <h3>Оплата</h3>
        <h6>Оплатить ваше бронирование можно следующими способами:</h6>
        <ul>
          <li>Банковской картой</li>
          <li>Система быстрых платежей</li>
          <li>Tinkoff Pay</li>
        </ul>
        <p>Оплата осуществляется через АО «Тинькофф Банк»</p>
        <p>
          Услуга оплаты через интернет осуществляется в соответствии с Правилами
          международных платежных систем Visa, MasterCard и Платежной системы
          МИР на принципах соблюдения конфиденциальности и безопасности
          совершения платежа, для чего используются самые современные методы
          проверки, шифрования и передачи данных по закрытым каналам связи. Ввод
          данных банковской карты осуществляется на защищенной платежной
          странице АО «Тинькофф Банк»
        </p>
        <h6>Реквизиты организации:</h6>
        <ul>
          <li>ИП Паламонов Юрий Игоревич</li>
          <li>ИНН: 771576017760</li>
        </ul>
      </div>
    </main>
  );
};
export default Payment;
