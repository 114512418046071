import { useState } from "react";
import { formatPhoneNumber } from "react-phone-number-input";
import { BiArrowBack } from "react-icons/bi";
import { useBool } from "hooks";
import { ReactComponent as IconWarning } from "icons/icon-warning.svg";
import { PasswordRecovery } from "components";
import UpdateName from "./UpdateName";
import UpdateEmail from "./UpdateEmail";
import UpdatePassword from "./UpdatePassword";
import UpdatePhone from "./UpdatePhone";

import { useSelector } from "react-redux";
import { selectUser } from "features/userSlice";

const AccountSettings = ({ animate, handleShowSettings }) => {
  const [currentSetting, setCurrentSetting] = useState(null);
  const forgotPassword = useBool();

  const user = useSelector(selectUser);

  const settings = [
    {
      name: "name",
      title: "имя",
      label: "Фамилия Имя",
      type: "text",
      value: user.name,
    },
    {
      name: "email",
      title: "email",
      label: "Адрес электронной почты",
      type: "email",
      value: user.email,
    },
    {
      name: "phone",
      title: "телефон",
      label: "Телефон",
      type: "tel",
      value: formatPhoneNumber(user.phone),
    },
    {
      name: "password",
      title: "пароль",
      label: "Пароль",
      type: "password",
      value: "••••••••",
    },
  ];

  if (forgotPassword.value)
    return <PasswordRecovery forgotPassword={forgotPassword} />;

  if (currentSetting?.name === "name")
    return (
      <UpdateName
        currentSetting={currentSetting}
        setCurrentSetting={setCurrentSetting}
        handleClose={handleShowSettings}
      />
    );

  if (currentSetting?.name === "email")
    return (
      <UpdateEmail
        currentSetting={currentSetting}
        setCurrentSetting={setCurrentSetting}
        handleClose={handleShowSettings}
      />
    );

  if (currentSetting?.name === "phone")
    return (
      <UpdatePhone
        currentSetting={currentSetting}
        setCurrentSetting={setCurrentSetting}
        handleClose={handleShowSettings}
      />
    );

  if (currentSetting?.name === "password")
    return (
      <UpdatePassword
        currentSetting={currentSetting}
        setCurrentSetting={setCurrentSetting}
        handleClose={handleShowSettings}
        forgotPassword={forgotPassword}
      />
    );

  return (
    <section
      className={animate ? "card settings-card primary" : "card settings-card"}
      aria-label="Настройки аккаунта"
    >
      <header className="settings-header">
        <h5>Настройки аккаунта</h5>
        <span
          className="btn-inline settings-close"
          onClick={handleShowSettings}
        >
          Закрыть
        </span>
      </header>
      <ul className="settings-list">
        {settings.map((el) => {
          return (
            <li
              key={el.name}
              className="setting-item"
              onClick={() => setCurrentSetting(el)}
            >
              <span className="setting-item-title">{el.title}</span>
              {el.name === "email" && !user.email_confirmed && (
                <IconWarning
                  className="icon-warning"
                  title="Адрес электронной почты не подтвержден"
                />
              )}
              <span className={`setting-item-value ${el.name}-value`}>
                {el.value}
              </span>
              <BiArrowBack className="icon-arrow" />
            </li>
          );
        })}
      </ul>
    </section>
  );
};

export default AccountSettings;
