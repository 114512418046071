import { VscArrowLeft } from "react-icons/vsc";
import { useLocation, useNavigate } from "react-router-dom";
import { ReservationHeader, Auth } from "components";
import { ReactComponent as IconEmpty } from "icons/icon_empty.svg";
import List from "./List";

import { useSelector } from "react-redux";
import { selectReservationCount } from "features/reservationSlice";
import { selectUser } from "features/userSlice";
import { selectLastLocation } from "features/appSlice";

const Reservation = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const reservationCount = useSelector(selectReservationCount);
  const lastLocation = useSelector(selectLastLocation);
  const user = useSelector(selectUser);

  const goToPreviousPath = () => {
    lastLocation ? navigate(lastLocation) : navigate(-1);
  };

  if (reservationCount === 0)
    return (
      <main
        className="error-main pad"
        aria-label="пустой список бронирований"
        key={location.key}
      >
        <div className="container">
          <IconEmpty />
          <p className="error-title">Ваша корзина пуста</p>
          <button className="btn btn-primary" onClick={() => navigate("/")}>
            На главную страницу
          </button>
          <button
            className="btn btn-outline"
            onClick={() => navigate("/account")}
          >
            История бронирований
          </button>
        </div>
      </main>
    );

  return (
    <main className="reservation-main pad" key={location.key}>
      <div className="container">
        <button className="btn-inline-icon btn-back" onClick={goToPreviousPath}>
          <VscArrowLeft />
          Назад
        </button>
        <ReservationHeader step="2" />
        {!user && (
          <div className="auth-container">
            <Auth isSignup />
          </div>
        )}
        <List />
      </div>
    </main>
  );
};

export default Reservation;
