import { ReactComponent as Tg } from "icons/tg.svg";

const Telegram = () => {
  return (
    <article className="tg-banner" aria-label="KhimkiBC Events в Telegram">
      <div className="content">
        <Tg />
        <h5>Мы в Telegram</h5>
        <p>
          Подписывайся на канал, чтобы получать самую актуальную информацию по
          мероприятиям Khimki BC и Space. Самые выгодные предложения и самые
          большие скидки!
        </p>
      </div>
      <a
        className="btn btn-primary"
        href="https://t.me/khimkibc_events"
        target="blank"
      >
        Подписаться
      </a>
    </article>
  );
};

export default Telegram;
