import { useLocation, Navigate } from "react-router-dom";
import AuthCard from "./AuthCard";

import { useSelector } from "react-redux";
import { selectUser } from "features/userSlice";

const Auth = ({ isSignup }) => {
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  const user = useSelector(selectUser);

  if (user && !location.state) {
    return <Navigate to="/account" replace />;
  }

  if (location.pathname === "/login" || location.pathname === "/signup")
    return (
      <main className="auth-main pad" key={location.key}>
        <div className="container">
          <AuthCard isSignup={isSignup} from={from} />
        </div>
      </main>
    );

  return <AuthCard isSignup={isSignup} key={location.key} />;
};

export default Auth;
