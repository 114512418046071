import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {
  Layout,
  Home,
  Auth,
  Event,
  Account,
  Reservation,
  Checkout,
  Order,
  Status,
  PhoneConfirm,
  Error,
  Loader,
  ServiceMsg,
  Archive,
  UserAgreement,
  PrivacyPolicy,
  Coupon,
  Contact,
  Payment,
  Tv,
} from "components";
import { RefetchAccount, ScrollToTop, RequireAuth } from "helpers";

const App = () => {
  return (
    <Router>
      <Loader />
      <RefetchAccount />
      <ScrollToTop exceptions={["event"]} />
      <ServiceMsg />
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/archive" element={<Archive />} />
          <Route path="/event/:id" element={<Event.Event />}>
            <Route index element={<Event.EventDescription />} />
            <Route path="participants" element={<Event.EventParticipants />} />
          </Route>
          <Route path="/login" element={<Auth />} />
          <Route path="/signup" element={<Auth isSignup />} />
          <Route path="/confirmation" element={<PhoneConfirm />} />
          <Route
            path="/account"
            element={
              <RequireAuth>
                <Account />
              </RequireAuth>
            }
          />
          <Route path="/reservation" element={<Reservation />} />
          <Route
            path="/reservation/:id"
            element={
              <RequireAuth>
                <Order />
              </RequireAuth>
            }
          />
          <Route
            path="/checkout"
            element={
              <RequireAuth>
                <Checkout />
              </RequireAuth>
            }
          />
          <Route
            path="/checkout/success"
            element={
              <RequireAuth>
                <Status />
              </RequireAuth>
            }
          />
          <Route path="/coupon" element={<Coupon />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/payment" element={<Payment />} />
          <Route path="/user-agreement" element={<UserAgreement />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/events_tv" element={<Tv />} />
          <Route path="*" element={<Error />} />
        </Routes>
      </Layout>
    </Router>
  );
};

export default App;
