import { useDocumentTitle } from "hooks";
import Footer from "./Footer";
import Header from "./Header";
import { useLocation } from "react-router-dom";

const Layout = ({ children }) => {
  const { pathname } = useLocation();
  useDocumentTitle();

  if (pathname === "/events_tv") return children;

  return (
    <div className="wrapper">
      <Header />
      {children}
      <Footer />
    </div>
  );
};

export default Layout;
