import { ReactComponent as IconSold } from "icons/icon-soldout.svg";
import { Link } from "react-router-dom";

const EventSoldout = () => {
  return (
    <div className="suits-list-empty" data-soldout={true}>
      <IconSold />
      <div className="text">
        <h6>Набрано максимальное количество участников мероприятия</h6>
        <p>
          Выберите{" "}
          <Link className="text-link" to={"/"}>
            другое событие
          </Link>{" "}
          или зайдите на эту страницу позже. Есть небольшая вероятность, что в
          случае отказов появятся места.
        </p>
      </div>
    </div>
  );
};

export default EventSoldout;
