const loadTinkoff = (callback) => {
  const existingScript = document.getElementById("tinkoff");

  if (!existingScript) {
    const script = document.createElement("script");
    script.src = "https://securepay.tinkoff.ru/html/payForm/js/tinkoff_v2.js";
    script.id = "tinkoff";
    document.body.appendChild(script);
    script.onload = () => {
      if (callback) callback();
    };
  }

  if (existingScript && callback) callback();
};

export default loadTinkoff;
