import { BiArrowBack } from "react-icons/bi";
import { InputField } from "components/inputs";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import { useChangePasswordMutation } from "app/authApi";

const UpdatePassword = ({
  currentSetting,
  setCurrentSetting,
  handleClose,
  forgotPassword,
}) => {
  const { label, name, title, type } = currentSetting;

  const [changePassword] = useChangePasswordMutation();

  const schema = Yup.object().shape({
    oldPassword: Yup.string()
      .required("Поле не может быть пустым")
      .min(8, "Неверный пароль"),
    newPassword: Yup.string()
      .required("Поле не может быть пустым")
      .min(8, "Пароль должен содержать не менее 8 символов")
      .notOneOf(
        [Yup.ref("oldPassword"), null],
        "Новый пароль должен отличаться от старого"
      ),
    confirmPassword: Yup.string()
      .required("Поле не может быть пустым")
      .oneOf([Yup.ref("newPassword"), null], "Пароли не совпадают"),
  });
  const validationSchema = { resolver: yupResolver(schema) };

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm(validationSchema);

  const onSubmit = async (data) => {
    const { oldPassword, newPassword, confirmPassword } = data;
    try {
      await changePassword({
        old_password: oldPassword,
        new_password: newPassword,
        confirm_password: confirmPassword,
      }).unwrap();
      setCurrentSetting(null);
    } catch (err) {
      setError(
        "oldPassword",
        {
          type: "manual",
          message: "Неверный пароль",
        },
        { shouldFocus: true }
      );
    }
  };

  return (
    <section className="card settings-card" aria-label="Настройки аккаунта">
      <header className="settings-header">
        <h5
          className="settings-header-back"
          onClick={() => setCurrentSetting(null)}
        >
          <BiArrowBack />
          Изменить {title}
        </h5>
        <span className="btn-inline settings-close" onClick={handleClose}>
          Закрыть
        </span>
      </header>
      <form className="setting-form" onSubmit={handleSubmit(onSubmit)}>
        <InputField
          label={label}
          id={`current-${name}-input`}
          type={type}
          {...register("oldPassword")}
          error={errors.oldPassword?.message}
          helperLink={
            <span className="btn-inline form-link" onClick={forgotPassword.on}>
              Забыли пароль?
            </span>
          }
        />
        <InputField
          label="Новый пароль"
          id={`new-${name}-input`}
          type={type}
          {...register("newPassword")}
          error={errors.newPassword?.message}
        />
        <InputField
          label="Подтвердите новый пароль"
          id={`confirm-new-${name}-input`}
          type={type}
          {...register("confirmPassword")}
          error={errors.confirmPassword?.message}
        />
        <div className="setting-form-controls">
          <button
            className="btn btn-outline"
            type="reset"
            onClick={() => setCurrentSetting(null)}
          >
            Отмена
          </button>
          <button className="btn btn-primary" type="submit">
            Сохранить
          </button>
        </div>
      </form>
    </section>
  );
};

export default UpdatePassword;
