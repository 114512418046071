import { useNavigate } from "react-router-dom";
import { useLocaleDate } from "hooks";
import { BASE_URL } from "helpers/constants";
import { localePrice } from "helpers/locales";

const SuitInfo = ({ event, suit, editable }) => {
  const navigate = useNavigate();
  const localeDate = useLocaleDate();

  const currentPackage = event.packages.find(
    (el) => el.packageName === suit.options.package
  );

  const namedQty = () => {
    switch (suit.options.quantity) {
      case 1:
        return "одноместное размещение";
      case 2:
      case 3:
      case 4:
        return `${suit.options.quantity}-х местное размещение`;
      case 7:
      case 8:
        return `${suit.options.quantity}-ми местное размещение`;
      default:
        return `${suit.options.quantity}-ти местное размещение`;
    }
  };

  const reservationSubTitle =
    suit.options.quantity === 1 && suit.options.type === "Место в номере"
      ? "место в номере"
      : `${namedQty()} (номер целиком)`;

  const arrival = new Date(currentPackage.arrivalDate);
  const departure = new Date(currentPackage.departureDate);

  const handleClick = () => {
    navigate("/reservation", { state: suit.id });
  };

  return (
    <article className="suit-info">
      <header className="suit-info-header card-header">
        <div className="suit-info-img card-img">
          <img src={`${BASE_URL}${suit.image}`} alt="img" />
        </div>
        <div className="suit-info-title flex-1">
          <h5>{suit.name}</h5>
          {suit.reservation_type !== 2 && <span>{reservationSubTitle}</span>}
        </div>
      </header>
      <div className="suit-info-package">
        <strong>{suit.options.package}</strong>
        <div className="package-dates">
          <div className="package-date">
            <small>Заезд</small>
            <p>
              {localeDate(arrival)}
              {` `}
              {arrival.getFullYear()}
            </p>
            <small className="time">
              {currentPackage?.arrivalTime.replace("-", " – ")}
            </small>
          </div>
          <div className="package-date">
            <small>Отъезд</small>
            <p>
              {localeDate(departure)}
              {` `}
              {departure.getFullYear()}
            </p>
            <small className="time">
              {currentPackage?.departureTime.replace("-", " – ")}
            </small>
          </div>
        </div>
      </div>
      <div className="participants-list">
        {suit.participants.map((el, id) => {
          return (
            <div key={id} className="participant">
              <header>
                <h6>{el.name}</h6>
                <div className="participant-tags">
                  <code>{el.age?.split(" ")[0]}</code>
                  <code>{el.participantType}</code>
                </div>
              </header>
              <ul className="participant-cost">
                {el.price.discount.discount !== 0 ||
                el.price.insurance !== 0 ||
                el.price.overprice.overprice !== 0 ? (
                  <li>
                    <p>Базовая стоимость</p>
                    <p className="base">{localePrice(el.price.base_price)}</p>
                  </li>
                ) : (
                  ""
                )}
                {el.price.overprice.overprice !== 0 ? (
                  <li>
                    <p>{el.price.overprice.name}</p>
                    <p className="base">
                      + {localePrice(el.price.overprice.overprice)}
                    </p>
                  </li>
                ) : (
                  ""
                )}
                {el.price.discount.discount !== 0 ? (
                  <li>
                    <p>{el.price.discount.name}</p>
                    <p className="discount">
                      - {localePrice(el.price.discount.discount)}
                    </p>
                  </li>
                ) : (
                  ""
                )}
                {el.price.insurance ? (
                  <li>
                    <p>Страховка от невыезда</p>
                    <p className="insurance">
                      {localePrice(el.price.insurance)}
                    </p>
                  </li>
                ) : (
                  ""
                )}
                <li>
                  <p>Цена</p>
                  <p className="total">{localePrice(el.price.price)}</p>
                </li>
              </ul>
            </div>
          );
        })}
      </div>
      {editable && (
        <button className="btn btn-outline small" onClick={handleClick}>
          Редактировать
        </button>
      )}
    </article>
  );
};

export default SuitInfo;
