import { useEffect, useState } from "react";
import {
  NavLink,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { VscArrowLeft } from "react-icons/vsc";
import { FiUsers, FiAlignLeft } from "react-icons/fi";
import { ReservationHeader, Error } from "components";
import EventGallery from "./EventGallery";
import EventDescription from "./EventDescription";
import EventParticipants from "./EventParticipants";
import Suits from "./Suits";
import DiscountModal from "./DiscountModal";
import SpecialRequestForm from "./SpecialRequestForm";
import PromoCode from "./PromoCode";
import { BASE_URL } from "helpers/constants";

import { useGetEventBySlugQuery } from "app/eventsApi";
import { useDispatch } from "react-redux";
import { setMessage } from "features/appSlice";

const Event = () => {
  const { id } = useParams();
  const { pathname } = useLocation();
  const [tab, setTab] = useState(0);
  const [promoError, setPromoError] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [params, setParams] = useSearchParams();
  const queryPromo = params.get("promo_code");

  const {
    data: event,
    isLoading,
    isUninitialized,
    refetch,
  } = useGetEventBySlugQuery({ slug: id, promo: queryPromo });

  useEffect(() => {
    if (queryPromo && event?.promo_code) {
      if (event.promo_code?.error) {
        setPromoError({ ...event.promo_code.error, promo: queryPromo });

        dispatch(
          setMessage({
            type: "error",
            msg: event.promo_code.error?.error || "Неверный промокод",
          })
        );
      }
      setParams(
        (p) => {
          p.delete("promo_code");
          return p;
        },
        { replace: true }
      );
    }
  }, [queryPromo, setParams, event?.promo_code, dispatch]);

  useEffect(() => {
    if (pathname.includes("participants")) {
      setTab(1);
    } else {
      setTab(0);
    }
  }, [pathname]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  const skeletonStyle = {
    color: "transparent",
    background: "#dcdde0",
    borderRadius: ".25rem",
    mixBlendMode: "soft-light",
  };

  if (!isUninitialized && !isLoading && !event) return <Error />;

  return (
    <>
      <DiscountModal />
      <main className="event-main pad">
        <div className="container">
          <header className="event-header">
            <button
              className="btn-inline-icon btn-back"
              onClick={() => navigate(event?.archived ? "/archive" : "/")}
            >
              <VscArrowLeft />
              Назад
            </button>
            <div className="event-title-container">
              {event?.is_partner && <i>мероприятие партнера</i>}
              {event?.archived ? null : (
                <EventCapacity
                  sold_out={event?.sold_out}
                  capacity={event?.capacity}
                  sportsmen_count={event?.sportsmen_count}
                />
              )}
              <div className="event-title">
                <h2>
                  {event?.header || <div style={skeletonStyle}>Загрузка</div>}
                </h2>
                <span className="event-date">
                  {event?.date.replace("-", "–") || (
                    <span style={skeletonStyle}>Загрузка – Загрузка</span>
                  )}
                </span>
              </div>
              {(event?.participants_types_descr ||
                event?.available_sport_list) && (
                <div className="event-types-container">
                  {event?.available_sport_list && (
                    <div className="event-types">
                      <small>Виды спорта:</small>
                      <ul>
                        {event.available_sport_list.map((el, id) => (
                          <li key={id}>
                            {el.name}
                            {el.smile}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                  {event?.participants_types_descr && (
                    <div className="event-types">
                      <small>Участники:</small>
                      <ul>
                        {event.participants_types_descr.map((el, id) => (
                          <li key={id}>{el}</li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              )}
            </div>
          </header>
          {event ? (
            <EventGallery img={event.image.map((el) => `${BASE_URL}${el}`)} />
          ) : (
            <SwiperSkeleton />
          )}
          {!event?.archived && (
            <PromoCode
              eventSlug={id}
              eventPromo={event?.promo_code}
              promoError={promoError}
              setPromoError={setPromoError}
            />
          )}
          <section className="event-info">
            <nav className={`event-info-nav tab-${tab}`}>
              <NavLink
                to={{
                  pathname: ".",
                  search: params.toString(),
                }}
                end
                replace
                className="btn btn-tab"
              >
                <FiAlignLeft /> Описание
              </NavLink>
              <NavLink
                to={{
                  pathname: "./participants",
                  search: params.toString(),
                }}
                end
                replace
                className="btn btn-tab"
              >
                <FiUsers />
                Участники
              </NavLink>
            </nav>
            {event ? (
              <Outlet
                context={{
                  text: event.text,
                  description: event.description,
                  participants: event.participants,
                  refund: event.refund,
                }}
              />
            ) : (
              <DescSkeleton />
            )}
          </section>
          {event && !event.archived && (
            <>
              <ReservationHeader step="1" />
              <Suits event={event} refetch={refetch} />
              <SpecialRequestForm />
            </>
          )}
        </div>
      </main>
    </>
  );
};

const EventCapacity = ({ sold_out, capacity, sportsmen_count }) => {
  if (sold_out && typeof capacity === "number")
    return (
      <div className="capacity">
        <div className="legend">
          <span>Свободных мест:</span>
          <strong>0/{capacity}</strong>
        </div>
        <div className="progress">
          <div className="indicator" style={{ width: "100%" }} />
        </div>
      </div>
    );

  if (
    typeof capacity === "number" &&
    typeof sportsmen_count === "number" &&
    capacity - sportsmen_count >= 0 &&
    sportsmen_count > capacity * 0.1
  )
    return (
      <div className="capacity">
        <div className="legend">
          <span>Свободных мест:</span>
          <strong>
            {capacity - sportsmen_count}/{capacity}
          </strong>
        </div>
        <div className="progress">
          <div
            className="indicator"
            style={{
              width: `${(sportsmen_count / capacity) * 100}%`,
            }}
          />
        </div>
      </div>
    );

  return null;
};

const SwiperSkeleton = () => {
  return (
    <section className="event-gallery" aria-label="галлерея">
      <div className="swiper">
        <div className="swiper-pagination">
          <div className="swiper-pagination-bullet swiper-pagination-bullet-active" />
        </div>
        <div className="swiper-wrapper">
          <div className="swiper-slide" style={{ aspectRatio: "1.66/1" }}></div>
        </div>
      </div>
    </section>
  );
};

const DescSkeleton = () => {
  return (
    <article className="event-description" aria-label="описание выезда">
      <div className="event-description-text" data-skeleton={true}>
        {Array(3)
          .fill()
          .map((_, id) => (
            <div key={id} className="p-skeleton"></div>
          ))}
      </div>
    </article>
  );
};

const elements = { Event, EventDescription, EventParticipants };

export default elements;
