import { forwardRef } from "react";

const RadioGroup = forwardRef(
  ({ label, arr, option, error, available, ...rest }, ref) => {
    const isAvailable = (el) => (available ? available.includes(el) : true);

    return (
      <div className={error ? "radio-container error" : "radio-container"}>
        <strong>
          {label} {error && <span className="required-mark">*</span>}
        </strong>
        {arr.map((el, id) => (
          <label
            className={`${
              isAvailable(el.value) ? "radio-btn" : "radio-btn disabled"
            }`}
            title={
              isAvailable(el.value) ? undefined : "Недоступно для этого номера"
            }
            key={id}
          >
            {el.name}
            <input
              ref={ref}
              {...rest}
              type="radio"
              value={el.value}
              disabled={!isAvailable(el.value)}
            />
            <span className="checkmark"></span>
          </label>
        ))}
        {error && <span className="error-msg">{error}</span>}
      </div>
    );
  }
);

export default RadioGroup;
