import { ReactComponent as ArrowLeft } from "icons/icon-arrow-left.svg";
import { ReactComponent as ArrowRight } from "icons/icon-arrow-right.svg";

import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/scss";

const EventGallery = ({ img }) => {
  const pagination = {
    clickable: true,
    renderBullet: (index, className) => {
      return `<span class=${className}></span>`;
    },
  };

  return (
    <section className="event-gallery" aria-label="галлерея">
      {img.length > 1 ? (
        <Swiper
          loop
          modules={[Pagination, Navigation]}
          pagination={pagination}
          navigation={{
            prevEl: ".prev",
            nextEl: ".next",
          }}
        >
          {img.map((img, id) => {
            return (
              <SwiperSlide key={id}>
                <img src={img} alt={img} />
              </SwiperSlide>
            );
          })}
          <ArrowLeft className="prev" />
          <ArrowRight className="next" />
        </Swiper>
      ) : (
        <div className="swiper">
          <div className="swiper-slide">
            <img src={img[0]} alt={img} />
          </div>
        </div>
      )}
    </section>
  );
};

export default EventGallery;
