import { useNavigate } from "react-router-dom";
import { FiClock } from "react-icons/fi";
import { BsCheckCircleFill } from "react-icons/bs";
import { ReactComponent as IconBonus } from "icons/icon_bonus.svg";
import { useMediaQuery } from "hooks";
import { localePrice } from "helpers/locales";

const OrdersList = ({ orders }) => {
  const navigate = useNavigate();
  const mobile = useMediaQuery("mobile");

  const checkStatus = (status) => {
    switch (status) {
      case "Не оплачено":
        return "failure";
      case "Частично":
        return "partially";
      case "Оплачено":
        return "success";
      default:
        return;
    }
  };

  return (
    <div className="orders-list">
      {mobile ? (
        <div className="orders-header table-grid">
          {["Мероприятие", "Цена", "Оплата"].map((el) => (
            <small key={el}>{el}</small>
          ))}
        </div>
      ) : (
        <div className="orders-header table-grid">
          {["Мероприятие", "Номер", "Цена", "Оплата"].map((el) => (
            <small key={el}>{el}</small>
          ))}
        </div>
      )}
      {orders.map((el) => (
        <div
          key={el.order_id}
          className="orders-list-card card table-grid"
          onClick={() => navigate(`/reservation/${el.order_uuid}`)}
        >
          <div className="order-name">
            <i
              className="event-status"
              data-complete={el.status_closed || undefined}
              title={el.status_closed ? "Завершено" : "Не завершено"}
            >
              {el.status_closed ? <BsCheckCircleFill /> : <FiClock />}
            </i>
            <div>
              <span>{el.header}</span>
              <small>{el.date}</small>
            </div>
          </div>
          {!mobile && <code className="order-id">{el.order_id}</code>}
          <div className="order-price">
            <span>
              {el.discount
                ? localePrice(el.discount.price)
                : localePrice(el.price)}
            </span>
            {el.bonuses && (
              <small title="Начислено бонусных баллов">
                +{el.bonuses.toLocaleString("ru-RU")}
                <IconBonus />
              </small>
            )}
          </div>
          <span className="payment-status">
            <span className="payment-status-label">{el.status_payment}</span>
            <span
              className={`payment-status-icon ${checkStatus(
                el.status_payment
              )}`}
            ></span>
          </span>
        </div>
      ))}
    </div>
  );
};

export default OrdersList;
