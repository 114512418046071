const Tinkoff = ({ values }) => {
  if (values)
    return (
      <>
        <input
          className="tinkoffPayRow"
          type="hidden"
          name="terminalkey"
          value="1638126817819"
        />
        <input
          className="tinkoffPayRow"
          type="hidden"
          name="frame"
          value="false"
        />
        <input
          className="tinkoffPayRow"
          type="hidden"
          name="language"
          value="ru"
        />
        <input
          className="tinkoffPayRow"
          type="hidden"
          placeholder="Сумма заказа"
          name="amount"
          defaultValue={values.amount}
          required
        />
        <input
          className="tinkoffPayRow"
          type="hidden"
          placeholder="Номер заказа"
          name="order"
          defaultValue={values.order}
        />
        <input
          className="tinkoffPayRow"
          type="hidden"
          placeholder="Описание заказа"
          name="description"
          defaultValue={values.description}
        />
        <input
          className="tinkoffPayRow"
          type="hidden"
          placeholder="ФИО плательщика"
          name="name"
          defaultValue={values.name}
        />
        <input
          className="tinkoffPayRow"
          type="hidden"
          placeholder="E-mail"
          name="email"
          defaultValue={values.email}
        />
        <input
          className="tinkoffPayRow"
          type="hidden"
          placeholder="Контактный телефон"
          name="phone"
          defaultValue={values.phone}
        />
        <input
          className="t-payform-row"
          type="hidden"
          name="receipt"
          defaultValue={JSON.stringify(values.receipt)}
        />
      </>
    );
  return null;
};

export default Tinkoff;
