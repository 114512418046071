export const loadState = () => {
  const APP_VERSION = process.env.REACT_APP_CURRENT_GIT_SHA;
  if (
    typeof localStorage.APP_VERSION === "undefined" ||
    localStorage.APP_VERSION === null
  )
    localStorage.setItem("APP_VERSION", APP_VERSION);
  if (localStorage.APP_VERSION !== APP_VERSION) localStorage.clear();

  try {
    const serializedState = localStorage.getItem("store");
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("store", serializedState);
  } catch (err) {}
};
