import { Link } from "react-router-dom";
import { BASE_URL } from "helpers/constants";
import Countdown from "./Countdown";
import { ReactComponent as IconSold } from "icons/icon-soldout.svg";

const EventCard = ({ event }) => {
  return (
    <article className="card event-card">
      <Link to={`/event/${event.slug}`}>
        <header
          className="event-card-header"
          data-countdown={event.sale_start || undefined}
          data-soldout={event.sold_out || undefined}
        >
          {event.video_cover ? (
            <video src={event.video_cover} autoPlay muted loop playsInline />
          ) : (
            <img src={`${BASE_URL}${event.image[0]}`} alt={event.title} />
          )}
          {event.sale_start && <Countdown date={event.sale_start} />}
          {event.sold_out && <IconSold className="soldout" />}
        </header>
      </Link>
      <div className="event-card-content">
        <span className="event-card-date">{event.date.replace("-", "–")}</span>
        <div className="event-card-title">
          <h5>{event.header}</h5>
          {event.is_partner && <i>мероприятие партнера</i>}
          {event.available_sport_list && (
            <div className="event-card-sport">
              {event.available_sport_list.map((el, id) => (
                <span key={id} title={el.name}>{el.smile}</span>
              ))}
            </div>
          )}
        </div>
        <Link className="btn btn-primary" to={`/event/${event.slug}`}>
          Подробнее
        </Link>
      </div>
    </article>
  );
};

export default EventCard;
