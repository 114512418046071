import { forwardRef } from "react";

const InputCheckbox = forwardRef(({ label, ...rest }, ref) => (
  <div className="checkbox-container">
    <label className="checkbox">
      {label}
      <input type="checkbox" ref={ref} {...rest} />
      <span className="checkmark"></span>
    </label>
  </div>
));

export default InputCheckbox;
