import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  lastLocation: null,
  message: null,
  isModal: false,
};

export const appSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setMessage: (state, action) => {
      state.message = action.payload;
    },
    clearMessage: (state) => {
      state.message = null;
    },
    setLastLocation: (state, action) => {
      state.lastLocation = action.payload;
    },
    setIsModal: (state, action) => {
      state.isModal = action.payload;
    },
  },
});

export const selectMessage = (state) => state.global.message;
export const selectLastLocation = (state) => state.global.lastLocation;
export const selectIsModal = (state) => state.global.isModal;
export const selectIsLoading = (state) => {
  const newObj = { ...state.api.queries, ...state.api.mutations };
  return Object.values(newObj).some((entry) => entry.status === "pending");
};

export const { setMessage, clearMessage, setLastLocation, setIsModal } =
  appSlice.actions;

export default appSlice.reducer;
