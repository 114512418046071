import { useGetArchiveEventsQuery } from "app/eventsApi";
import { VscArrowLeft } from "react-icons/vsc";
import EventsList from "components/Home/EventsList";
import { useNavigate } from "react-router-dom";

const Archive = () => {
  const navigate = useNavigate();
  const { data } = useGetArchiveEventsQuery();

  return (
    <main className="archive-main pad">
      <div className="container">
        <button
          className="btn-inline-icon btn-back"
          onClick={() => navigate("/")}
        >
          <VscArrowLeft />
          Назад
        </button>
        <h3>Архив событий</h3>
        <EventsList eventsData={data} />
      </div>
    </main>
  );
};

export default Archive;
