import { Link } from "react-router-dom";
import { ReactComponent as IconBonus } from "icons/icon_bonus.svg";
import { Modal } from "components";
import { useCloseModal } from "components/Modal";

import { useDispatch } from "react-redux";
import { setMessage } from "features/appSlice";
import {
  useGetBonusHistoryQuery,
  useGetOldBonusesMutation,
} from "app/bonusApi";

const BonusModal = ({ bonus, userId }) => {
  const handleClose = useCloseModal();

  const dispatch = useDispatch();
  const [getOldBonuses] = useGetOldBonusesMutation();
  const { data: bonusHistory } = useGetBonusHistoryQuery(userId);

  const handleGetOldBonuses = async () => {
    try {
      const result = await getOldBonuses().unwrap();
      const data = result.data;

      if (!data.old_events_data) {
        dispatch(
          setMessage({
            type: "error",
            msg: "Не найдено заказов за этот период",
          })
        );
      } else {
        dispatch(
          setMessage({
            type: "success",
            msg: `Найдено ${data.old_events_data.length} заказов на сумму ${data.total} баллов`,
          })
        );
      }
    } catch (err) {
      dispatch(
        setMessage({
          type: "error",
          msg: "Что-то пошло не так, попробуйте позже",
        })
      );
    }
  };

  return (
    <Modal name="bonus">
      <header className="bonus-modal-header">
        <h4>Программа лояльности</h4>
        <div className="bonus-amount">
          <div>
            {bonus?.toLocaleString("ru-RU")}
            <IconBonus />
          </div>
          <span>Бонусных баллов на счету</span>
        </div>
      </header>
      <section
        className="bonus-list"
        aria-label="История операций бонусных баллов"
      >
        <h6>История операций</h6>
        {bonusHistory?.length > 0 ? (
          <div>
            <div className="table-grid table-grid-header">
              {["Операция", "Транзакция", "Сумма"].map((el, id) => (
                <small key={id}>{el}</small>
              ))}
            </div>
            {bonusHistory.map((el) => (
              <div
                className="table-grid table-grid-row"
                key={el.transaction_id}
              >
                <div className="operation">
                  <span className="operation-type">{el.type}</span>
                  <span className="operation-date">{el.date}</span>
                </div>
                {el.reservation_uuid ? (
                  <Link
                    to={`/reservation/${el.reservation_uuid}`}
                    className="text-link"
                    data-theme="light"
                  >
                    {el.reservation}
                  </Link>
                ) : (
                  <span>{el.transaction_title}</span>
                )}
                <div className="amount" data-income={el.isIncome}>
                  {el.amount.toLocaleString("ru-RU")}
                  <IconBonus />
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div
            className={`${
              !bonusHistory ? "empty-orders loading" : "empty-orders"
            }`}
          >
            <span>{bonusHistory && "История операций пуста"}</span>
          </div>
        )}
        <button
          className="btn btn-primary"
          style={{ alignSelf: "center" }}
          onClick={() => handleGetOldBonuses()}
        >
          Запросить баллы за период 1 января 2020 - 10 мая 2022
        </button>
      </section>
      <button className="btn btn-outline" onClick={handleClose}>
        Закрыть окно
      </button>
    </Modal>
  );
};
export default BonusModal;
