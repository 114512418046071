import { useLocation, Navigate } from "react-router-dom";

import { useSelector } from "react-redux";
import { selectUser } from "features/userSlice";

const RequireAuth = ({ children }) => {
  const user = useSelector(selectUser);
  const location = useLocation();

  const isLogout = location.state?.logout ?? false;

  if (isLogout) {
    return <Navigate to="/" />;
  }

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return children;
};

export default RequireAuth;
