import { useEffect, useRef } from "react";
import { FiX } from "react-icons/fi";
import { useOutsideClick } from "hooks";

import { useDispatch, useSelector } from "react-redux";
import { selectIsModal, setIsModal } from "features/appSlice";

export const useCloseModal = () => {
  const doc = document.documentElement;
  const dispatch = useDispatch();

  const handleClose = () => {
    doc.removeAttribute("style");
    dispatch(setIsModal(false));
  };

  return handleClose;
};

const Modal = ({ name, children }) => {
  const articleRef = useRef(null);
  const handleClose = useCloseModal();

  const isModal = useSelector(selectIsModal);
  const doc = document.documentElement;

  useOutsideClick(articleRef, handleClose);

  useEffect(() => {
    if (isModal) {
      doc.style.overflow = "hidden";
    }
  }, [isModal, doc]);

  if (!isModal || isModal.modalName !== name) return null;

  return (
    <div className="modal-container pad">
      <div className="container">
        <article className={`${`${name}-modal`} modal card`} ref={articleRef}>
          <button
            className="close-modal"
            aria-label="Закрыть окно"
            onClick={handleClose}
          >
            <FiX />
          </button>
          {children}
        </article>
      </div>
    </div>
  );
};

export default Modal;
