import { useEffect, useState } from "react";

/**
 *
 * @param {"desktop" | "tablet" | "mobile"} query
 */
const useMediaQuery = (query) => {
  switch (query) {
    case "desktop":
      query = "(min-width: 1024px)";
      break;
    case "tablet":
      query = "(max-width: 768px)";
      break;
    case "mobile":
      query = "(max-width: 576px)";
      break;

    default:
      break;
  }

  const getMatches = (query) => {
    if (typeof window !== "undefined") {
      return window.matchMedia(query).matches;
    }
    return false;
  };

  const [matches, setMatches] = useState(getMatches(query));

  useEffect(() => {
    const matchMedia = window.matchMedia(query);
    const handleChange = () => setMatches(getMatches(query));

    handleChange();

    matchMedia.addEventListener("change", handleChange);

    return () => matchMedia.removeEventListener("change", handleChange);
  }, [query]);

  return matches;
};

export default useMediaQuery;
