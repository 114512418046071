import { FaRegTrashAlt } from "react-icons/fa";
import { useLocaleDate } from "hooks";

import { useDispatch, useSelector } from "react-redux";
import { remove, selectReservationId } from "features/reservationSlice";
import { useDeleteSuitMutation } from "app/eventsApi";

const DetailsSuit = ({
  event,
  suit,
  eventId,
  suitId,
  name,
  selectedPackage,
}) => {
  const localeDate = useLocaleDate();

  const dispatch = useDispatch();
  const [deleteSuit] = useDeleteSuitMutation();
  const reservationId = useSelector(selectReservationId);

  const currentPackage = event.packages.find(
    (el) => el.packageName === selectedPackage
  );

  const arrival = new Date(currentPackage?.arrivalDate);
  const departure = new Date(currentPackage?.departureDate);

  const handleRemoveReservation = async () => {
    try {
      await deleteSuit({
        reservation_uuid: reservationId,
        event_room_id: suit.id,
      });

      dispatch(remove({ eventId, suitId }));
    } catch (err) {}
  };

  return (
    <div className="details-card-suit">
      <header className="details-card-suit-header">
        <strong>{name}</strong>
        <button
          className="btn-inline-icon btn-delete"
          onClick={handleRemoveReservation}
          aria-label="удалить бронирование"
        >
          <FaRegTrashAlt />
        </button>
      </header>
      <div className="package-dates">
        <div className="package-date">
          <small>Заезд</small>
          <p>
            {localeDate(arrival)}
            {` `}
            {arrival.getFullYear()}
          </p>
          <small className="time">
            {currentPackage?.arrivalTime.replace("-", " – ")}
          </small>
        </div>
        <div className="package-date">
          <small>Отъезд</small>
          <p>
            {localeDate(departure)}
            {` `}
            {departure.getFullYear()}
          </p>
          <small className="time">
            {currentPackage?.departureTime.replace("-", " – ")}
          </small>
        </div>
      </div>
    </div>
  );
};

export default DetailsSuit;
