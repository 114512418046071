import { Link } from "react-router-dom";
import { FaVk, FaTelegramPlane } from "react-icons/fa";
import { ReactComponent as Logo } from "icons/logo-footer.svg";
import { ReactComponent as IconKhimki } from "icons/icon-khimki.svg";

const socials = [
  {
    url: "https://xbadm.ru/",
    label: "Бадминтонный клуб Химки",
    icon: <IconKhimki />,
  },
  {
    url: "https://t.me/khimkibc_events",
    label: "Телеграм Khimki BC Events",
    icon: <FaTelegramPlane />,
  },
  {
    url: "https://vk.com/xbadm",
    label: "Вконтакте Khimki BC",
    icon: <FaVk />,
  },
];

const navItems = [
  {
    name: "Подарочный купон",
    url: "/coupon",
  },
  {
    name: "Оплата",
    url: "/payment",
  },
  {
    name: "Контакты",
    url: "/contact",
  },
];

const Footer = () => {
  const year = new Date().getFullYear();

  const handleClick = () => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <footer className="footer pad">
      <div className="container">
        <div className="footer-row">
          <Link
            to="/"
            aria-label="Логотип khimkibc.events главная страница"
            className="logo-footer"
            onClick={handleClick}
          >
            <Logo />
          </Link>
          <div className="socials">
            {socials.map((el, id) => (
              <a href={el.url} target="blank" aria-label={el.label} key={id}>
                {el.icon}
              </a>
            ))}
          </div>
          <nav className="footer-nav">
            {navItems.map((el, id) => (
              <Link key={id} to={el.url}>
                {el.name}
              </Link>
            ))}
          </nav>
        </div>
        <div className="footer-row">
          <span className="copy">&copy; {year} Khimki Badminton Club</span>
          <small>
            Продолжая использовать наш сайт, вы даете согласие на{" "}
            <Link to="/privacy-policy" className="text-link">
              Обработку персональных данных
            </Link>{" "}
            в соответствии с{" "}
            <Link to="/user-agreement" className="text-link">
              Пользовательским соглашением
            </Link>
            .
          </small>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
