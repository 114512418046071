import { useNavigate } from "react-router-dom";

const Error = () => {
  const navigate = useNavigate();

  return (
    <main className="error-main pad">
      <div className="container">
        <span className="error-code">404</span>
        <p className="error-title">Такой страницы не существует</p>
        <button className="btn btn-primary" onClick={() => navigate("/")}>
          На главную
        </button>
      </div>
    </main>
  );
};

export default Error;
