import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const useDocumentTitle = (text = "Организация спортивных сборов по бадминтону, сквошу и настольному теннису") => {
  useLocation();

  useEffect(() => {
    document.title = text;
  });
};

export default useDocumentTitle;
