import Telegram from "../Home/Telegram";

const eventsData = [
  {
    name: "Телефон",
    value: "+7 (995) 100-38-38",
  },
  {
    name: "График приема звонков",
    value: "ежедневно с 10:00 до 20:00",
  },
  {
    name: "E-mail",
    value: <a href="mailto:events@xbadm.ru">events@xbadm.ru</a>,
  },
];

const secondaryData = [
  {
    title:
      "Контакты организации оказывающей услуги по физической культуре и спорту для бадминтонного клуба Химки:",
    data: [
      {
        name: "Название организации",
        value: "ИП Паламонов Юрий Игоревич",
      },
      {
        name: "Телефон",
        value: "+7 (909) 920-22-62",
      },
      {
        name: "E-mail",
        value: <a href="mailto:palamonov@xbadm.ru">palamonov@xbadm.ru</a>,
      },
      {
        name: "ИНН",
        value: "771576017760",
      },
    ],
  },
  {
    title: "НП «Профессиональный бадминтонный клуб «Химки»»",
    data: [
      {
        name: "Телефон",
        value: "+7 (495) 149-99-95",
      },
      {
        name: "Сайт",
        value: <a href="www.xbadm.ru">www.xbadm.ru</a>,
      },
      {
        name: "E-mail",
        value: <a href="mailto:info@xbadm.ru">info@xbadm.ru</a>,
      },
    ],
  },
];

const Contact = () => {
  return (
    <main className="contact-main pad">
      <h3>Контакты</h3>
      <div className="container">
        <article
          className="contact"
          aria-label="Контакты бадминтонного клуба Химки"
        >
          <ul>
            {eventsData.map((el, id) => (
              <li key={id}>
                <span>{el.name}:</span>
                <strong>{el.value}</strong>
              </li>
            ))}
          </ul>
          <Telegram />
        </article>
        {secondaryData.map((el, id) => (
          <article className="org" key={id}>
            <h6>{el.title}</h6>
            <ul>
              {el.data.map((item, i) => (
                <li key={i}>
                  <span>{item.name}:</span>
                  <strong>{item.value}</strong>
                </li>
              ))}
            </ul>
          </article>
        ))}
      </div>
    </main>
  );
};

export default Contact;
