import { BiArrowBack } from "react-icons/bi";
import { InputField } from "components/inputs";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import { useDispatch } from "react-redux";
import { useUpdateAccountMutation } from "app/authApi";
import { setMessage } from "features/appSlice";

const UpdateName = ({ currentSetting, setCurrentSetting, handleClose }) => {
  const { label, name, title, type, value } = currentSetting;

  const [updateAccount] = useUpdateAccountMutation();
  const dispatch = useDispatch();

  const schema = Yup.object().shape({
    name: Yup.string()
      .required("Поле не может быть пустым")
      .matches(/^[а-яА-Я-a-zA-ZЁё\s]+$/, "Недопустимые символы")
      .min(3, "Должно содержать не менее трех букв"),
  });
  const validationSchema = { resolver: yupResolver(schema) };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm(validationSchema);

  const onSubmit = async (data) => {
    if (value === data[name]) {
      return setCurrentSetting(null);
    }

    try {
      await updateAccount({ first_name: data[name] }).unwrap();
      setCurrentSetting(null);
      dispatch(
        setMessage({ type: "success", msg: "Имя было успешно изменено" })
      );
    } catch (err) {}
  };

  return (
    <section className="card settings-card" aria-label="Настройки аккаунта">
      <header className="settings-header">
        <h5
          className="settings-header-back"
          onClick={() => setCurrentSetting(null)}
        >
          <BiArrowBack />
          Изменить {title}
        </h5>
        <span className="btn-inline settings-close" onClick={handleClose}>
          Закрыть
        </span>
      </header>
      <form className="setting-form" onSubmit={handleSubmit(onSubmit)}>
        <InputField
          label={label}
          id={`change-${name}-input`}
          type={type}
          defaultValue={value}
          {...register(name)}
          error={errors[name]?.message}
        />
        <div className="setting-form-controls">
          <button
            className="btn btn-outline"
            type="reset"
            onClick={() => setCurrentSetting(null)}
          >
            Отмена
          </button>
          <button className="btn btn-primary" type="submit">
            Сохранить
          </button>
        </div>
      </form>
    </section>
  );
};

export default UpdateName;
