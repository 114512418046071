import useMediaQuery from "./useMediaQuery";

const useLocaleDate = () => {
  const mobile = useMediaQuery("mobile");

  const dateOptions = {
    weekday: "short",
    month: `${!mobile ? "long" : "short"}`,
    day: "numeric",
  };

  const localeDate = (date) => date.toLocaleString("ru-RU", dateOptions);

  return localeDate;
};

export default useLocaleDate;
