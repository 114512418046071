import { BASE_URL } from "helpers/constants";
import { useBool } from "hooks";
import { useOutletContext } from "react-router-dom";
import { VscArrowDown } from "react-icons/vsc";
import { useRef } from "react";
// import { ReactComponent as IconBadm } from "icons/icon-badm.svg";
// import { ReactComponent as IconSquash } from "icons/icon-squash.svg";
// import { ReactComponent as IconTable } from "icons/icon-table.svg";

const EventParticipants = () => {
  const { participants } = useOutletContext();
  const showMore = useBool();
  const listRef = useRef(null);

  const limit = 15;
  const total = participants.users.length;
  const remainder = total - limit > 0 ? total - limit : null;

  const handleResult = () => {
    if (remainder && !showMore.value) {
      return participants.users.slice(0, limit);
    }
    return participants.users;
  };

  const users = handleResult();

  const handleClick = () => {
    showMore.value && window.scroll({ top: listRef.current.offsetTop });
    showMore.toggle();
  };

  return (
    <article className="event-participants" aria-label="участники выезда">
      <div className="event-participants-item">
        <h5>Тренеры и организаторы</h5>
        <div className="event-participants-list trainers-list">
          {participants.trainers.map((el, id) => (
            <div key={id} className="event-participants-list-item trainer">
              <img src={`${BASE_URL}${el.img}`} alt={el.name} />
              <div className="trainer-name">
                <div className="trainer-name-text">
                  <span>{el.name}</span>
                  {/* {id === 0 && (
                    <div className="sport">
                      <IconBadm />
                      <span>бадминтон</span>
                    </div>
                  )}
                  {id === 1 && (
                    <div className="sport">
                      <IconSquash />
                      <span>сквош</span>
                    </div>
                  )}
                  {id === 2 && (
                    <div className="sport">
                      <IconTable />
                      <span>настольный теннис</span>
                    </div>
                  )} */}
                </div>
                <div className="trainer-tags">
                  {el.role?.split(",").map((tag, id) => (
                    <code key={id}>{tag}</code>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {users.length > 0 && (
        <div className="event-participants-item">
          <h5>Участники</h5>
          <ol className="event-participants-list" ref={listRef}>
            {users.map((el, id) => (
              <li key={id} className="event-participants-list-item user">
                {el.name} <code>{el.type}</code>
              </li>
            ))}
          </ol>
          {remainder && (
            <button
              className={
                !showMore.value
                  ? "btn-inline-icon toggle-content"
                  : "btn-inline-icon toggle-content active"
              }
              style={{ maxWidth: "fit-content" }}
              onClick={handleClick}
            >
              <VscArrowDown />
              {!showMore.value ? `и еще ${remainder}` : "скрыть участников"}
            </button>
          )}
        </div>
      )}
    </article>
  );
};

export default EventParticipants;
