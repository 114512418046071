import { useController } from "react-hook-form";

const RadioGroupIcons = ({
  name,
  control,
  label,
  options,
  error,
  available = [],
  availableGender,
  setValue,
  ...props
}) => {
  const { field } = useController({ name, control });
  const isAvailable = (el) =>
    Array.isArray(available) ? available.includes(el) : true;
  const isSingleGenderAvailable =
    Array.isArray(availableGender) && availableGender.length === 1;

  const onChange = (e) => {
    field.onChange(e);
    setValue("gender", "");
    if (e.target.value === "Место в номере") {
      setValue("quantity", 1);
      if (isSingleGenderAvailable) {
        setValue("gender", availableGender[0]);
      }
    } else {
      setValue("quantity", 0);
    }
  };

  return (
    <div className={error ? "radio-container error" : "radio-container"}>
      <strong>
        {label} {error && <span className="required-mark">*</span>}
      </strong>
      <div className="radio-icons-container">
        {options.map((el, id) => (
          <label
            className={`${
              isAvailable(el.name) ? "radio-btn" : "radio-btn disabled"
            }`}
            title={
              isAvailable(el.name) ? undefined : "Недоступно для этого номера"
            }
            data-active={field.value === el.name || undefined}
            key={id}
          >
            {el.icon}
            {el.name}
            <input
              {...props}
              {...{ ...field, onChange }}
              checked={field.value === el.name}
              type="radio"
              value={el.name}
              disabled={!isAvailable(el.name)}
            />
            <span className="checkmark"></span>
          </label>
        ))}
      </div>
      {error && <span className="error-msg">{error}</span>}
    </div>
  );
};

export default RadioGroupIcons;
