import { useEffect } from "react";
import { VscArrowLeft } from "react-icons/vsc";
import { useNavigate, useParams } from "react-router-dom";
import { SuitInfo, Error } from "components";
import Summary from "./Summary";
import BalanceModal from "./BalanceModal";

import { useSelector } from "react-redux";
import { selectUser } from "features/userSlice";
import { useGetOrderMutation } from "app/eventsApi";
import { selectIsModal } from "features/appSlice";

const Order = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const user = useSelector(selectUser);
  const isModal = useSelector(selectIsModal);
  const [getOrder, { data: order, isUninitialized, isLoading }] =
    useGetOrderMutation();

  useEffect(() => {
    const handleGetOrder = async () => {
      try {
        await getOrder({
          user_id: user.id,
          order_uuid: id,
        }).unwrap();
      } catch (err) {}
    };
    handleGetOrder();
  }, [getOrder, user.id, id]);

  const checkStatus = () => {
    switch (order?.status) {
      case "Не оплачено":
        return "failure";
      case "Частично":
        return "partially";
      case "Оплачено":
        return "success";
      default:
        return;
    }
  };

  if (isUninitialized || isLoading)
    return (
      <main className="order-main pad">
        <div className="container"></div>
      </main>
    );

  if (!order) return <Error />;

  return (
    <>
      {isModal && <BalanceModal />}
      <main className="order-main pad">
        <div className="container">
          <button
            className="btn-inline-icon btn-back"
            onClick={() => navigate("/account")}
          >
            <VscArrowLeft />
            Назад
          </button>
          <div className="order-container card-list">
            <div className="order-info">
              <div className="order-header">
                <div className="order-header-title">
                  <h4>Бронирование №{order.order_id}</h4>
                  <div className={`order-badge ${checkStatus()}`}>
                    {order.status}
                  </div>
                </div>
                <span className="order-header-date">{order.date}</span>
              </div>
              <article
                className="card card-container"
                aria-label="Информация о бронировании"
              >
                <h5 className="card-event-name">{order?.header}</h5>
                {order.suits.map((suit) => {
                  return (
                    <SuitInfo
                      key={suit.id}
                      event={order}
                      suit={suit}
                      editable={false}
                    />
                  );
                })}
              </article>
            </div>
            <Summary order={order} />
          </div>
        </div>
      </main>
    </>
  );
};

export default Order;
