import { useEffect } from "react";
import { FaGift } from "react-icons/fa";
import { ReactComponent as IconSpinner } from "icons/icon-spinner.svg";
import { ReactComponent as IconWarning } from "icons/icon-warning.svg";
import { InputField } from "components/inputs";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import { useDispatch } from "react-redux";
import {
  useAddPromoByEventSlugMutation,
  useDeletePromoByEventSlugMutation,
} from "app/eventsApi";
import { setMessage } from "features/appSlice";
import { Link, useLocation } from "react-router-dom";

const PromoCode = ({ eventSlug, eventPromo, promoError, setPromoError }) => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const [addPromoCode, { isLoading: isSubmitting }] =
    useAddPromoByEventSlugMutation();
  const [deletePromoCode, { isLoading: isDeleting }] =
    useDeletePromoByEventSlugMutation();

  const schema = Yup.object().shape({
    promoCode: Yup.string().required("Поле не может быть пустым"),
  });

  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    watch,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema), reValidateMode: undefined });

  const isValid = watch("promoCode") ? true : false;

  useEffect(() => {
    if (errors.promoCode) {
      setTimeout(() => {
        clearErrors();
      }, 5000);
    }
  }, [errors.promoCode, clearErrors]);

  const onSubmit = async (data) => {
    try {
      const res = await addPromoCode({
        slug: eventSlug,
        promo: data.promoCode,
      }).unwrap();

      if (res.promo_code?.error) {
        if (res.promo_code.error?.status_code === "401") {
          setPromoError({ ...res.promo_code.error, promo: data.promoCode });
          document.activeElement.blur();
        }
        setError("promoCode", {
          type: "manual",
          message: res.promo_code.error?.error || "Промокод не найден",
        });
      } else {
        setPromoError(null);
        dispatch(
          setMessage({ type: "success", msg: "Промокод был успешно применен" })
        );
        reset();
      }
    } catch (err) {
      setError("promoCode", {
        type: "manual",
        message: err.data?.error || "Промокод не найден",
      });
    }
  };

  const handleDeletePromo = async () => {
    try {
      await deletePromoCode({ slug: eventSlug, promo: eventPromo.name });
    } catch (err) {}
  };

  return (
    <form className="checkout-promo" onSubmit={handleSubmit(onSubmit)}>
      <h5>Есть промокод?</h5>
      {promoError?.status_code === "401" && (
        <div className="checkout-promo-info">
          <IconWarning />
          <small>
            Для применения персонального промокода необходимо{" "}
            <Link
              to="/login"
              state={{
                from: {
                  pathname: `${pathname}?promo_code=${promoError.promo}`,
                },
              }}
              className="text-link"
            >
              авторизоваться
            </Link>
          </small>
        </div>
      )}
      {eventPromo && !promoError ? (
        <div className="checkout-promo-success" data-event>
          <div className="checkout-promo-success-text">
            <FaGift />
            <span>
              {eventPromo.name}: скидка {eventPromo.discount}%
            </span>
          </div>
          <button
            type="button"
            className="btn-upper"
            onClick={handleDeletePromo}
            data-delete
          >
            {isDeleting ? <IconSpinner className="spinner" /> : "Удалить"}
          </button>
        </div>
      ) : (
        <InputField
          autoComplete="off"
          placeholder="Введите промокод"
          {...register("promoCode")}
          error={errors.promoCode?.message}
        >
          <button
            type="submit"
            className="btn-upper"
            disabled={!isValid || undefined}
          >
            {isSubmitting ? <IconSpinner className="spinner" /> : "Добавить"}
          </button>
        </InputField>
      )}
    </form>
  );
};

export default PromoCode;
