import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector, useDispatch } from "react-redux";
import * as Yup from "yup";
import "yup-phone-lite";

import { useBool, useCountDown } from "hooks";
import { InputField, InputPhone } from "components/inputs";
import { selectUser } from "features/userSlice";
import { setMessage } from "features/appSlice";
import {
  useRemindPasswordChangePasswordMutation,
  useRemindPasswordCodeConfirmMutation,
  useRemindPasswordMutation,
  useResendSmsConfirmationMutation,
  useResendVoiceCallConfirmationMutation,
  useSendSmsConfirmationMutation,
} from "app/authApi";

const PasswordRecovery = ({ forgotPassword }) => {
  const confirmState = useBool();
  const resetState = useBool();
  const [recoveryData, setRecoveryData] = useState({});
  const { timeLeft } = useCountDown(recoveryData.expired_at);

  const user = useSelector(selectUser);
  const [remindPassword] = useRemindPasswordMutation();
  const [codeConfirm] = useRemindPasswordCodeConfirmMutation();
  const [changePassword] = useRemindPasswordChangePasswordMutation();
  const [resendCallConfirmation] = useResendVoiceCallConfirmationMutation();
  const [sendMessageConfirmation] = useSendSmsConfirmationMutation();
  const [resendMessageConfirmation] = useResendSmsConfirmationMutation();

  const dispatch = useDispatch();

  const initialSchema = Yup.object().shape({
    phone: Yup.string()
      .ensure()
      .required("Поле не может быть пустым")
      .phone("RU", "Некорректный номер телефона"),
  });
  const confirmSchema = Yup.object().shape({
    phone: Yup.string()
      .ensure()
      .required("Поле не может быть пустым")
      .phone("RU", "Некорректный номер телефона"),
    confirm: Yup.string().required("Поле не может быть пустым"),
  });
  const resetSchema = Yup.object().shape({
    newPassword: Yup.string()
      .required("Поле не может быть пустым")
      .min(8, "Пароль должен содержать не менее 8 символов"),
    confirmPassword: Yup.string()
      .required("Поле не может быть пустым")
      .oneOf([Yup.ref("newPassword"), null], "Пароли не совпадают"),
  });

  const validationSchema = {
    resolver: yupResolver(
      confirmState.value
        ? resetState.value
          ? resetSchema
          : confirmSchema
        : initialSchema
    ),
  };

  const {
    register,
    setError,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm(validationSchema);

  const onSubmit = async (data) => {
    if (!confirmState.value) {
      try {
        const res = await remindPassword({ phone: data.phone }).unwrap();
        setRecoveryData(res);
        confirmState.on();
      } catch (err) {
        setError(
          "phone",
          {
            type: "manual",
            message: "Пользователя с таким номером телефона не существует",
          },
          { shouldFocus: true }
        );
      }
    }
    if (confirmState.value && !resetState.value) {
      try {
        const res = await codeConfirm({
          ...recoveryData,
          code: data.confirm,
        }).unwrap();
        setRecoveryData(res);
        resetState.on();
      } catch (err) {
        setError(
          "confirm",
          {
            type: "manual",
            message: "Неверный код подтверждения",
          },
          { shouldFocus: true }
        );
      }
    }
    if (confirmState.value && resetState.value) {
      try {
        await changePassword({
          ...recoveryData,
          password: data.newPassword,
          confirmPassword: data.confirmPassword,
        }).unwrap();
        forgotPassword.off();
        dispatch(
          setMessage({ type: "success", msg: "Пароль был успешно изменен" })
        );
      } catch (err) {}
    }
  };

  const resendCode = async () => {
    try {
      let res = {};

      if (recoveryData.code_delivery === 2) {
        if (recoveryData.available_attempts_amount > 0) {
          res = await resendCallConfirmation({
            confirm_token_hash: recoveryData.token_hash,
          }).unwrap();
        } else {
          res = await sendMessageConfirmation({
            phone: recoveryData.phone,
            confirmation_type: 5,
          }).unwrap();
        }
      } else {
        res = await resendMessageConfirmation({
          confirm_token_hash: recoveryData.token_hash,
        }).unwrap();
      }

      setRecoveryData(res);
      dispatch(
        setMessage({
          type: "success",
          msg: "Код подтверждения отправлен повторно",
        })
      );
    } catch (err) {
      dispatch(
        setMessage({
          type: "error",
          msg:
            err?.data?.error ||
            "Время повторной отправки не истекло. Попробуйте позже",
        })
      );
    }
  };

  if (resetState.value)
    return (
      <article
        className="card settings-card auth-card"
        aria-label="авторизация"
      >
        <header className="auth-header">
          <h4>Сброс пароля</h4>
        </header>
        <form className="setting-form" onSubmit={handleSubmit(onSubmit)}>
          <InputField
            label="Новый пароль"
            id="new-password-input"
            type="password"
            {...register("newPassword")}
            error={errors.newPassword?.message}
          />
          <InputField
            label="Подтвердите новый пароль"
            id="confirm-new-password-input"
            type="password"
            {...register("confirmPassword")}
            error={errors.confirmPassword?.message}
          />
          <div className="setting-form-controls recover-password-controls">
            <button
              className="btn btn-outline"
              type="reset"
              onClick={forgotPassword.off}
            >
              Отмена
            </button>
            <button className="btn btn-primary" type="submit">
              Сохранить
            </button>
          </div>
        </form>
      </article>
    );

  return (
    <article className="card settings-card auth-card" aria-label="авторизация">
      <header className="auth-header">
        <h4>Восстановление пароля</h4>
      </header>
      <form className="setting-form" onSubmit={handleSubmit(onSubmit)}>
        <small className="info-msg">
          Введите номер телефона указанный при регистрации и мы вышлем вам код
          подтверждения для восстановления пароля
        </small>
        <InputPhone
          label={"Телефон"}
          id="password-recovery-input"
          name="phone"
          defaultValue={user && user.phone}
          control={control}
          error={errors.phone?.message}
        />
        {confirmState.value && (
          <>
            <small className="info-msg">
              {recoveryData?.code_delivery === 2
                ? `Пожалуйста, введите последние 4 цифры номера телефона, с которого
              поступит звонок на указанный вами номер`
                : `Пожалуйста, введите код подтверждения отправленный на указанный вами номер`}
            </small>
            <InputField
              label="Код подтверждения"
              id="confirm-phone-input"
              pattern="[0-9]*"
              autoFocus="on"
              {...register("confirm")}
              error={errors.confirm?.message}
              helperLink={
                timeLeft > 0 ? (
                  <span className="helper-text helper-text-dark">
                    Повторная отправка кода возможна через{" "}
                    {Math.floor(timeLeft)} сек.
                  </span>
                ) : recoveryData?.code_delivery === 2 ? (
                  recoveryData?.available_attempts_amount > 0 ? (
                    <span className="btn-inline form-link" onClick={resendCode}>
                      Позвонить еще раз
                    </span>
                  ) : (
                    <span
                      className="helper-text-dark"
                      style={{ fontSize: ".75rem" }}
                    >
                      Звонок не поступил?{" "}
                      <span
                        className="btn-inline form-link"
                        onClick={resendCode}
                      >
                        Отправить SMS-код
                      </span>
                    </span>
                  )
                ) : (
                  <span className="btn-inline form-link" onClick={resendCode}>
                    Отправить код еще раз
                  </span>
                )
              }
            />
          </>
        )}
        <div className="setting-form-controls recover-password-controls">
          <button
            className="btn btn-outline"
            type="reset"
            onClick={forgotPassword.off}
          >
            Отмена
          </button>
          <button
            className="btn btn-primary"
            type="submit"
            style={{ whiteSpace: "nowrap" }}
          >
            {confirmState.value ? "Подтвердить" : "Отправить код"}
          </button>
        </div>
      </form>
    </article>
  );
};

export default PasswordRecovery;
