import { createSlice } from "@reduxjs/toolkit";
import { api } from "app/api";

const initialState = {
  user: null,
  token: null,
  temp: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateToken: (state, action) => {
      state.token = action.payload;
    },
    updateTempUser: (state, action) => {
      state.temp = action.payload;
    },
    signout: (state) => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(api.endpoints.getAccount.matchFulfilled, (state, action) => {
        const { first_name: name, ...rest } = action.payload;
        if (name) {
          state.user = { name, ...rest };
          state.temp = null;
        }
      })
      .addMatcher(
        api.endpoints.createAccount.matchFulfilled,
        (state, action) => {
          state.temp = action.payload;
        }
      )
      .addMatcher(api.endpoints.login.matchFulfilled, (state, action) => {
        const { user, token } = action.payload;
        const { first_name: name, ...rest } = user;
        state.user = { name, ...rest };
        state.token = token;
      })
      .addMatcher(
        api.endpoints.updateAccount.matchFulfilled,
        (state, action) => {
          const { first_name: name, email, phone } = action.payload;
          state.user = { ...state.user, name, email, phone };
        }
      )
      .addMatcher(api.endpoints.logout.matchFulfilled, (state) => {
        return initialState;
      });
  },
});

export const selectTemp = (state) => state.user.temp;
export const selectToken = (state) => state.user.token;
export const selectUser = (state) => state.user.user;

export const { signout, updateToken, updateTempUser } = userSlice.actions;

export default userSlice.reducer;
