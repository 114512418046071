import { useState } from "react";
import { Link } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import { FaRegCheckCircle } from "react-icons/fa";
import { VscArrowDown } from "react-icons/vsc";
import { Tinkoff } from "components";
import { loadTinkoff } from "helpers";
import { ReactComponent as IconBonus } from "icons/icon_bonus.svg";
import ReservationExp from "components/ReservationExp";
import { localePrice } from "helpers/locales";

import { useSelector, useDispatch } from "react-redux";
import { selectUser } from "features/userSlice";
import {
  useCheckoutReservationMutation,
  useGetReservationMutation,
} from "app/eventsApi";
import {
  selectReservationExpiry,
  selectReservationId,
  selectReservationObj,
} from "features/reservationSlice";
import { useBonusPayMutation } from "app/bonusApi";
import { setIsModal } from "features/appSlice";

const Summary = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const reservation = useSelector(selectReservationObj);
  const reservation_uuid = useSelector(selectReservationId);
  const reservationExpiry = useSelector(selectReservationExpiry);
  const [checkoutReservation, { data: values }] =
    useCheckoutReservationMutation();
  const [bonusPay] = useBonusPayMutation();
  const [getReservation] = useGetReservationMutation();

  const availableBonus = reservation.bonus.bonus_point_to_use;
  const isApplied = reservation.bonus.applied;

  const handleUseBonus = async () => {
    try {
      await bonusPay({
        reservation_uuid,
        applied: !isApplied,
      }).unwrap();
      await getReservation({
        user_id: user.id,
      }).unwrap();
    } catch (e) {}
  };

  const checkoutSubmit = async (e) => {
    e.preventDefault();
    try {
      await checkoutReservation({
        user_id: user.id,
        reservation_uuid,
      }).unwrap();
      loadTinkoff(() => {
        window.pay(e.target);
      });
    } catch (err) {}
    return false;
  };

  return (
    <section className="summary-container" aria-label="Сводка бронирования">
      <div className="summary-card card">
        {reservation.data.map((el) => {
          const selectedPrice = el.price.find((item) => item.is_selected);
          return (
            <div className="summary-event-group" key={el.slug}>
              <h6>{el.header}</h6>
              <ul className="summary-event-list">
                <li className="table-header">
                  <small>Номер / Пакет</small>
                  <small>Цена</small>
                </li>
                {el.suits.map((item) => {
                  return (
                    <li key={item.id}>
                      <p>{item.name}</p>
                      <span className="summary-price">
                        {localePrice(item.price)}
                      </span>
                    </li>
                  );
                })}
              </ul>
              <div className="summary-subtotal">
                <div className="summary-subtotal-row">
                  <span className="summary-price">
                    <small>Итого:</small>
                    {selectedPrice.discount
                      ? localePrice(selectedPrice.discount.price)
                      : localePrice(el.price[0].price)}
                  </span>
                  <Discount selectedPrice={selectedPrice} />
                </div>
                {selectedPrice.prepay && (
                  <div className="summary-subtotal-row">
                    <span className="summary-price">
                      <small>Предоплата:</small>
                      {localePrice(selectedPrice.price)}
                    </span>
                  </div>
                )}
              </div>
            </div>
          );
        })}
        <div className="summary-total">
          {availableBonus > 0 && (
            <div className="summary-total-item">
              {!isApplied ? (
                <>
                  <span style={{ display: "flex", gap: ".25rem" }}>
                    Доступно
                    <span className="bonus-inline">
                      {availableBonus.toLocaleString("ru-RU")}
                      <IconBonus />
                    </span>
                  </span>
                  <button className="btn-inline" onClick={handleUseBonus}>
                    Использовать
                  </button>
                </>
              ) : (
                <>
                  <span style={{ display: "flex", gap: ".25rem" }}>
                    <span className="bonus-inline">
                      {availableBonus.toLocaleString("ru-RU")}
                      <IconBonus />
                    </span>
                    <FaRegCheckCircle className="checkmark" />
                  </span>
                  <button className="btn-inline" onClick={handleUseBonus}>
                    Отменить
                  </button>
                </>
              )}
            </div>
          )}
          <div className="summary-total-item">
            <span>Будет начислено за заказ</span>
            <span className="bonus-badge">
              {reservation.bonus.bonus_point_accrue.toLocaleString("ru-RU")}
              <IconBonus />
            </span>
          </div>
          <div className="summary-total-item summary-total-final">
            <span>К оплате</span>
            <span className="final-price">
              <span>{localePrice(reservation.final_price)}</span>
              {isApplied && <small>{localePrice(reservation.pay_price)}</small>}
            </span>
          </div>
        </div>
      </div>
      {reservationExpiry && (
        <ReservationExp reservationExpiry={reservationExpiry} />
      )}
      <small className="summary-info">
        Оформляя это бронирование, вы принимаете{" "}
        <Link to="/user-agreement" className="text-link">
          пользовательское соглашение
        </Link>{" "}
        и{" "}
        <Link to="/privacy-policy" className="text-link">
          положение о конфиденциальности
        </Link>
        .
      </small>
      <form name="TinkoffPayForm" onSubmit={checkoutSubmit}>
        <Tinkoff values={values} />
        <button className="btn btn-primary-icon" type="submit" value="Оплатить">
          Перейти на страницу оплаты
          <BiArrowBack />
        </button>
      </form>
      <div className="balance-container">
        <button
          className="btn-balance"
          onClick={() => dispatch(setIsModal({ modalName: "balance" }))}
        >
          Оплатить с личного счета
        </button>
      </div>
    </section>
  );
};

const Discount = ({ selectedPrice }) => {
  const [showList, setShowList] = useState(false);

  if (!selectedPrice.discount) return null;

  return (
    <div className="summary-price-discount" data-show={showList || undefined}>
      <div
        className="summary-price-discount-row"
        onClick={() => setShowList(!showList)}
      >
        <del>{localePrice(selectedPrice.discount.amount)}</del>
        <span className="summary-price-badge">
          {selectedPrice.discount.name}%
        </span>
        <VscArrowDown />
      </div>
      <ul className="discount-list">
        {selectedPrice.discount?.list?.map((discount, id) => (
          <li key={id}>
            <small>{discount.text}</small>
            <span className="summary-price">{discount.name}%</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Summary;
