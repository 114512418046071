import { api } from "./api";

const eventsApi = api
  .enhanceEndpoints({
    addTagTypes: ["Events", "Event"],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getEvents: builder.query({
        query: () => "get_all_events/",
        providesTags: ["Events"],
      }),
      getArchiveEvents: builder.query({
        query: () => "get_all_archive_events/",
      }),
      getSlides: builder.query({
        query: () => "get_banners/",
        transformResponse: (res) => res.data,
      }),
      getEventBySlug: builder.query({
        query: ({ slug, promo }) => ({
          url: `get_events_by_slug/?slug=${slug}${
            promo ? `&promo_code=${promo}` : ""
          }`,
          credentials: "include",
        }),
        keepUnusedDataFor: 0,
        providesTags: ["Event"],
      }),
      addPromoByEventSlug: builder.mutation({
        query: ({ slug, promo }) => ({
          url: `get_events_by_slug/?slug=${slug}&promo_code=${promo}`,
          method: "POST",
          credentials: "include",
        }),
        invalidatesTags: ["Event"],
      }),
      deletePromoByEventSlug: builder.mutation({
        query: ({ slug, promo }) => ({
          url: `get_events_by_slug/?slug=${slug}&promo_code=${promo}`,
          method: "DELETE",
          credentials: "include",
        }),
        invalidatesTags: ["Event"],
      }),
      getEventInfo: builder.query({
        query: (params) => ({ url: "how_to_travel_cheaper/", params }),
      }),
      sendSpecialRequest: builder.mutation({
        query: (body) => ({
          url: "send_special_request/",
          method: "POST",
          body,
        }),
      }),
      addReservation: builder.mutation({
        query: (args) => ({
          url: "add_reservation/",
          method: "POST",
          body: args,
          credentials: "include",
        }),
        invalidatesTags: ["Events"],
        async onQueryStarted(args, { dispatch, queryFulfilled }) {
          try {
            const { data } = await queryFulfilled;
            if (data) {
              dispatch(
                eventsApi.endpoints.getReservation.initiate({
                  reservation_uuid: data.reservation_uuid,
                })
              );
            }
          } catch (err) {}
        },
      }),
      getReservation: builder.mutation({
        query: (args) => ({
          url: "get_reservation/",
          method: "POST",
          body: args,
        }),
      }),
      updateReservation: builder.mutation({
        query: (args) => ({
          url: "update_reservation/",
          method: "POST",
          body: args,
        }),
      }),
      updatePayment: builder.mutation({
        query: (args) => ({
          url: "update_reservation_payment/",
          method: "POST",
          body: args,
        }),
      }),
      addPromoCode: builder.mutation({
        query: (body) => ({
          url: "promo_code/",
          method: "POST",
          body,
        }),
      }),
      deletePromoCode: builder.mutation({
        query: (body) => ({
          url: "promo_code/",
          method: "DELETE",
          body,
          credentials: "include",
        }),
      }),
      checkoutReservation: builder.mutation({
        query: (args) => ({
          url: "reservation_success/",
          method: "POST",
          body: args,
        }),
      }),
      getOrders: builder.mutation({
        query: (args) => ({
          url: "get_user_orders/",
          method: "POST",
          body: args,
        }),
      }),
      getOrder: builder.mutation({
        query: (args) => ({
          url: "get_user_order_by_id/",
          method: "POST",
          body: args,
        }),
      }),
      deleteSuit: builder.mutation({
        query: (args) => ({
          url: "delete_room_in_reservation/",
          method: "POST",
          body: args,
        }),
        invalidatesTags: ["Events", "Event"],
      }),
      getFutureEvents: builder.query({
        query: () => ({
          url: "https://www.googleapis.com/calendar/v3/calendars/iujosk5c0qebktve095t88dhp4@group.calendar.google.com/events",
          params: {
            key: "AIzaSyCR3-ptjHE-_douJsn8o20oRwkxt-zHStY",
            singleEvents: true,
            orderBy: "starttime",
            timeMin: new Date().toISOString(),
            timeMax: `${new Date().getFullYear() + 1}-12-31T23:59:59.000Z`,
          },
        }),
        transformResponse: (res) =>
          res?.items
            ? res.items
                .map((el) => ({
                  dateStart: el.start.date,
                  dateEnd: el.end.date,
                  summary: el.summary,
                  description: el.description,
                }))
                .filter((el) =>
                  el.description?.match(/^(?=.*сборы)(?!.*http\b).*$/gim)
                )
            : [],
      }),
    }),
  });

export const {
  useGetEventsQuery,
  useGetArchiveEventsQuery,
  useGetSlidesQuery,
  useGetEventBySlugQuery,
  useAddPromoByEventSlugMutation,
  useDeletePromoByEventSlugMutation,
  useGetEventInfoQuery,
  useSendSpecialRequestMutation,
  useAddReservationMutation,
  useGetReservationMutation,
  useUpdateReservationMutation,
  useUpdatePaymentMutation,
  useAddPromoCodeMutation,
  useDeletePromoCodeMutation,
  useCheckoutReservationMutation,
  useGetOrdersMutation,
  useGetOrderMutation,
  useDeleteSuitMutation,
  useGetFutureEventsQuery,
} = eventsApi;
