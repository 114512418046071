import { useNavigate } from "react-router-dom";
import { InputField, InputPhone } from "components/inputs";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import "yup-phone-lite";

import { useLoginMutation } from "app/authApi";

const LogInForm = ({ from, forgotPassword }) => {
  const navigate = useNavigate();

  const [login] = useLoginMutation();

  const schema = Yup.object().shape({
    phone: Yup.string()
      .ensure()
      .required("Поле не может быть пустым")
      .phone("RU", "Некорректный номер телефона"),
    password: Yup.string()
      .required("Поле не может быть пустым")
      .min(8, "Неверный пароль"),
  });
  const validationSchema = { resolver: yupResolver(schema) };

  const {
    register,
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({ ...validationSchema, reValidateMode: undefined });

  const handleClick = () => {
    forgotPassword.on();
  };

  const onSubmit = async (data) => {
    try {
      await login({
        phone: data.phone,
        password: data.password,
      }).unwrap();
      from && navigate(from, { replace: true });
    } catch (err) {
      setError("phone", {
        type: "manual",
        message: ` `,
      });
      setError("password", {
        type: "manual",
        message: "Неверное имя пользователя или пароль",
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="auth-form">
      <InputPhone
        label="Телефон"
        name="phone"
        id="signup-phone-input"
        control={control}
        error={errors.phone?.message}
      />
      <InputField
        label="Пароль"
        id="login-password-input"
        type="password"
        {...register("password")}
        error={errors.password?.message}
        helperLink={
          <span className="btn-inline form-link" onClick={handleClick}>
            Забыли пароль?
          </span>
        }
      />
      <button className="btn btn-primary" type="submit">
        Войти
      </button>
    </form>
  );
};

export default LogInForm;
