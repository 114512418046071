import { createSlice } from "@reduxjs/toolkit";
import { api } from "app/api";

const formInitialValues = {
  name: "",
  phone: "",
  food: "",
  participantType: "",
  age: "",
  childAge: "",
  insurance: false,
  show_in_list: true,
  valid: false,
};

const initialState = {
  id: null,
  price: null,
  pay_price: null,
  final_price: null,
  bonus: null,
  expires_at: null,
  data: [],
};

export const reservationSlice = createSlice({
  name: "reservation",
  initialState,
  reducers: {
    clear: (state) => {
      return initialState;
    },
    addReservationId: (state, action) => {
      state.id = action.payload;
    },
    add: (state, action) => {
      const { eventData, suitData } = action.payload;
      const currentEvent = state.data.find(
        (el) => el.event_id === eventData.event_id
      );

      if (!currentEvent) {
        state.data.push({ ...eventData, suits: [suitData] });
      } else {
        currentEvent.suits.push(suitData);
      }
    },
    remove: (state, action) => {
      const { eventId, suitId } = action.payload;
      const currentEvent = state.data[eventId];

      if (currentEvent) {
        currentEvent.suits.splice(suitId, 1);
      }
      if (!currentEvent.suits.length && state.data.length) {
        state.data.splice(eventId, 1);
      }
      if (!state.data.length) {
        state.id = null;
        state.price = null;
        state.pay_price = null;
        state.final_price = null;
        state.bonus = null;
        state.expires_at = null;
      }
    },
    editSuit: (state, action) => {
      const { eventId, suitId, selects } = action.payload;
      const currentEvent = state.data[eventId];

      if (currentEvent) {
        const currentSuit = currentEvent.suits[suitId];
        if (currentSuit) {
          const diff = selects.quantity - currentSuit.participants.length;

          if (diff > 0) {
            for (let i = 0; i < diff; i++) {
              currentSuit.participants.push(formInitialValues);
            }
          }
          if (diff < 0) {
            currentSuit.participants = currentSuit.participants.slice(0, diff);
          }

          currentSuit.options = selects;
        }
      }
    },
    editParticipant: (state, action) => {
      const { eventId, suitId, participantId, form } = action.payload;
      const currentEvent = state.data[eventId];

      if (currentEvent?.suits?.[suitId]?.participants?.[participantId]) {
        currentEvent.suits[suitId].participants[participantId] = {
          ...currentEvent.suits[suitId].participants[participantId],
          ...form,
        };
      }
    },
    deleteParticipant: (state, action) => {
      const { eventId, suitId, participantId } = action.payload;
      const currentEvent = state.data[eventId];

      if (currentEvent) {
        currentEvent.suits[suitId].participants.splice(participantId, 1);

        currentEvent.suits[suitId].options.quantity =
          currentEvent.suits[suitId].options.quantity - 1;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        api.endpoints.updateReservation.matchFulfilled,
        (state, { payload }) => {
          const {
            reservation_uuid,
            reservation,
            price,
            pay_price,
            expires_at,
          } = payload;
          state.id = reservation_uuid;
          state.price = price;
          state.pay_price = pay_price;
          state.expires_at = expires_at;
          state.data = reservation;
        }
      )
      .addMatcher(
        api.endpoints.getReservation.matchFulfilled,
        (state, { payload }) => {
          const {
            reservation_uuid,
            reservation,
            price,
            pay_price,
            final_price,
            bonus,
            expires_at,
          } = payload;
          if (reservation) {
            if (!state.id) {
              state.id = reservation_uuid;
              state.price = price;
              state.pay_price = pay_price;
              state.final_price = final_price;
              state.bonus = bonus;
              state.expires_at = expires_at;
              state.data = reservation;
            } else {
              const newReservation = reservation.reduce((acc, el) => {
                const existingEvent = state.data.find(
                  (item) => item.event_id === el.event_id
                );
                if (!existingEvent) {
                  acc.push(el);
                } else {
                  const storeSuits = existingEvent.suits;
                  const serverSuits = reservation.find(
                    (item) => item.event_id === existingEvent.event_id
                  ).suits;
                  const newSuits = serverSuits.reduce((suitsAcc, suit) => {
                    const existingSuit = storeSuits.find(
                      (item) => item.id === suit.id
                    );
                    if (existingSuit && suit.options.quantity === 0) {
                      suitsAcc.push(existingSuit);
                    } else {
                      suitsAcc.push(suit);
                    }
                    return suitsAcc;
                  }, []);
                  acc.push({ ...el, suits: newSuits });
                }
                return acc;
              }, []);
              state.data = newReservation;
              state.pay_price = pay_price;
              state.final_price = final_price;
              state.bonus = bonus;
              state.expires_at = expires_at;
            }
          } else {
            return initialState;
          }
        }
      );
  },
});

// Selectors
export const selectReservationObj = (state) => state.reservation;
export const selectReservation = (state) => state.reservation.data;
export const selectReservationId = (state) => state.reservation.id;
export const selectReservationExpiry = (state) => state.reservation.expires_at;
export const selectReservationCount = (state) =>
  state.reservation.data.reduce((acc, el) => {
    acc += el.suits.length;
    return acc;
  }, 0);
export const selectValid = (state) =>
  state.reservation.data.every((el) =>
    el.suits.every(
      (suit) =>
        suit.options.valid &&
        suit.participants.length &&
        suit.participants.every((participant) => participant.valid)
    )
  );

// Action creators
export const {
  add,
  addReservationId,
  clear,
  remove,
  editSuit,
  editParticipant,
  deleteParticipant,
} = reservationSlice.actions;

export default reservationSlice.reducer;
