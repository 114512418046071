import { useState } from "react";
import { BsCheckCircle } from "react-icons/bs";
import { Modal } from "components";
import { InputField } from "components/inputs";
import { useCloseModal } from "components/Modal";
import { ReactComponent as IconSpinner } from "icons/icon-spinner.svg";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import { useDispatch, useSelector } from "react-redux";
import { useActivateCouponMutation } from "app/bonusApi";
import { selectUser } from "features/userSlice";
import { setIsModal, setMessage } from "features/appSlice";

const CouponModal = () => {
  const [couponData, setCouponData] = useState({});
  const closeModal = useCloseModal();

  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const [activateCoupon, { isLoading, isSuccess }] =
    useActivateCouponMutation();

  const schema = Yup.object().shape({
    coupon: Yup.string().required("Поле не может быть пустым"),
  });
  const validationSchema = { resolver: yupResolver(schema) };

  const {
    register,
    handleSubmit,
    setError,
    watch,
    reset,
    formState: { errors },
  } = useForm(validationSchema);

  const isValid = !!watch("coupon");

  const onSubmit = async (data) => {
    try {
      const res = await activateCoupon({
        user_id: user.id,
        coupon_numb: data.coupon.toUpperCase(),
      }).unwrap();
      setCouponData(res.data);
      dispatch(setIsModal({ modalName: "bonus" }));
      window.scrollTo({ top: 0 });
    } catch (err) {
      if (err.status === 400) {
        setError(
          "coupon",
          {
            type: "manual",
            message: err?.data?.data?.error || "Неверный номер купона",
          },
          { shouldFocus: true }
        );
      } else if (err.status === 500) {
        setError(
          "coupon",
          {
            type: "manual",
            message:
              err?.data?.data?.error || "Купон с таким номером уже активирован",
          },
          { shouldFocus: true }
        );
      } else {
        dispatch(
          setMessage({
            type: "error",
            msg: "Что-то пошло не так. Попробуйте позже",
          })
        );
      }
    }
  };

  return (
    <Modal name="coupon-activation">
      {!isSuccess ? (
        <>
          <header>
            <h4>Активировать подарочный купон</h4>
            <p>
              Для активации введите 8-значный номер указанный на купоне и
              нажмите кнопку "Активировать"
            </p>
          </header>
          <form onSubmit={handleSubmit(onSubmit)}>
            <InputField
              label="Номер купона"
              id="coupon-input"
              autoComplete="off"
              {...register("coupon")}
              error={errors.coupon?.message}
            />
            <div className="buttons">
              <button
                type="button"
                className="btn btn-outline"
                onClick={() => {
                  closeModal();
                  reset();
                }}
              >
                Отмена
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                data-disabled={!isValid || undefined}
                data-loading={isLoading || undefined}
                disabled={isLoading || undefined}
              >
                {isLoading ? (
                  <>
                    <IconSpinner
                      className="spinner"
                      style={{ marginRight: ".5rem" }}
                    />{" "}
                    Активация
                  </>
                ) : (
                  "Активировать"
                )}
              </button>
            </div>
          </form>
        </>
      ) : (
        <SuccessModal closeModal={closeModal} couponData={couponData} />
      )}
    </Modal>
  );
};

const SuccessModal = ({ closeModal, couponData }) => {
  return (
    <div className="activation-success">
      <BsCheckCircle />
      <div className="text">
        <h5>Купон успешно активирован!</h5>
        <p>
          {couponData?.sum
            ? `Вам начислено ${couponData?.sum} бонусных баллов`
            : "Вам начислены бонусные баллы"}
        </p>
      </div>
      <button
        type="button"
        className="btn btn-outline"
        onClick={() => {
          closeModal();
        }}
      >
        Закрыть окно
      </button>
    </div>
  );
};

export default CouponModal;
