import { VscArrowDown } from "react-icons/vsc";
import { Modal } from "components";
import { useCloseModal } from "components/Modal";

const ActivityModal = ({ activity, status }) => {
  const handleClose = useCloseModal();
  const currentYear = new Date().getFullYear();

  const listItems = [
    {
      name: `Ваши активности ${currentYear}`,
      coef: activity.current_year_coef,
      data: activity.current_year_reservations,
    },
    {
      name: `Ваши активности ${currentYear - 1}–${currentYear}`,
      coef: activity.previous_and_current_year_coef,
      data: [
        ...activity.previous_year_reservations,
        ...activity.current_year_reservations,
      ],
    },
  ];

  return (
    <Modal name="activity">
      <h4>Активность</h4>
      <div className="info">
        <div className="info-item">
          <small>Ваш статус:</small>
          <span className="activity-status">
            {status.icon}
            {status.name}
          </span>
        </div>
        <div className="info-item">
          <small>Индекс активности:</small>
          <span className="activity-coeff">
            {parseFloat(activity.main_coef)}
          </span>
        </div>
      </div>
      <details className="activity-info">
        <summary>
          <span>Подробнее о системе активности</span>
          <VscArrowDown />
        </summary>
        <ActivityDescription />
      </details>
      <div className="activity-list">
        {listItems.map((el, id) => (
          <details className="activity-table" key={id}>
            <summary>
              <h6>
                {el.name}
                {id === 0 && <span>x2</span>}
              </h6>
              <span
                className="activity-coeff"
                data-inactive={el.coef !== activity.main_coef || undefined}
              >
                {parseFloat(el.coef)}
              </span>
              <VscArrowDown />
            </summary>
            {el.data.length > 0 ? (
              <div>
                <div className="table-grid table-grid-header">
                  {["Мероприятие", "Баллы"].map((el, id) => (
                    <small key={id}>{el}</small>
                  ))}
                </div>
                {el.data.map((item, id) => (
                  <div className="table-grid table-grid-row" key={id}>
                    <div className="name">
                      <span className="title">
                        {item.event_title}
                        {item.isFriends ? " (за друзей)" : ""}
                      </span>
                      <span className="date">{item.date}</span>
                    </div>
                    <div className="amount">
                      <span>{item.coef}</span>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="activity-empty">
                У вас не было активности за этот период
              </div>
            )}
          </details>
        ))}
      </div>
      <button className="btn btn-outline" onClick={handleClose}>
        Закрыть окно
      </button>
    </Modal>
  );
};

const ActivityDescription = () => {
  return (
    <div className="text">
      <header>
        <h5>Как это работает?</h5>
        <p>
          Мы разработали бонусную программу для наших друзей - постоянных
          участников мероприятий. Накапливай баллы активности, и получай больше
          бонусов за участие.
        </p>
      </header>
      <article className="article">
        <h6>Как накопить баллы активности?</h6>
        <p>
          Прими участие в мероприятии (забронируй для себя), получи 1 балл
          активности. Забронируй поездку для друга или члена семьи - получи 0,5
          балла активности.
        </p>
      </article>
      <article className="article">
        <h6>Как считаются мои баллы активности, и за какой период?</h6>
        <p>Баллы активности рассчитываются в двух вариантах:</p>
        <ol>
          <li>Баллы активности текущего года, умноженные на 2.</li>
          <li>Баллы активности текущего и прошлого года.</li>
          <li>
            Какой из этих 2-х показателей окажется больше, такой и будет
            применен.
          </li>
        </ol>
      </article>
      <article className="article">
        <h6>
          Сколько баллов активности нужно накопить, чтобы получить премиальный
          статус?
        </h6>
        <ol>
          <li>
            Если вы зарегистрированы в системе - поздравляем, ваш статус Silver.
            Вы уже получаете бонусы.
          </li>
          <li>
            Еcли ваш индекс активности 4 и более - ваш статус - Gold, и вас ждут
            повышенные бонусные начисления.
          </li>
          <li>
            Еcли ваш индекс активности 8 более - ваш статус - Premium, и вас
            ждут максимальные бонусные начисления.
          </li>
        </ol>
      </article>
      <p>
        Индекс обновляется первого числа каждого месяца, учитываются события
        предыдущего месяца. Если вы поучаствовали в мероприятии, дождитесь 1-го
        числа следующего месяца, и убедитесь в начислении.
      </p>
    </div>
  );
};

export default ActivityModal;
