import { useCountDown } from "hooks";
import { BiTimeFive } from "react-icons/bi";

const ReservationExp = ({ reservationExpiry }) => {
  const { timeLeft, hours, minutes, seconds } = useCountDown(reservationExpiry);

  /**
   *
   * @param {number} val
   */
  const addLeadingZero = (val) => (val >= 10 ? val : `0${val}`);

  if (timeLeft)
    return (
      <div className="reservation-expiration">
        <BiTimeFive />
        <span>
          <span className="reservation-expiration-time">
            {timeLeft >= 0
              ? `${hours}:${addLeadingZero(minutes)}:${addLeadingZero(seconds)}`
              : "0:00:00"}
          </span>{" "}
          осталось на завершение бронирования и оплату
        </span>
      </div>
    );

  return null;
};

export default ReservationExp;
