import { forwardRef } from "react";

const RadioGroup = forwardRef(
  ({ label, arr, option, error, available, setValue, ...rest }, ref) => {
    const isAvailable = (el) =>
      Array.isArray(available) ? available.includes(el) : true;

    return (
      <div className={error ? "radio-container error" : "radio-container"}>
        <strong>
          {label} {error && <span className="required-mark">*</span>}
        </strong>
        {arr.map((el, id) => (
          <label
            className={`${
              isAvailable(el) ? "radio-btn" : "radio-btn disabled"
            }`}
            title={isAvailable(el) ? undefined : "Недоступно для этого номера"}
            key={id}
          >
            {el}
            <input
              ref={ref}
              {...rest}
              type="radio"
              value={el}
              disabled={!isAvailable(el)}
            />
            <span className="checkmark"></span>
          </label>
        ))}
        {error && <span className="error-msg">{error}</span>}
      </div>
    );
  }
);

export default RadioGroup;
