import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import { Link } from "react-router-dom";
import { ReactComponent as ArrowLeft } from "icons/icon-arrow-left.svg";
import { ReactComponent as ArrowRight } from "icons/icon-arrow-right.svg";
import { BASE_URL } from "helpers/constants";
import { useGetSlidesQuery } from "app/eventsApi";

import "swiper/scss";
import "swiper/scss/effect-fade";

const MainSlider = () => {
  const { data: slides, isLoading } = useGetSlidesQuery();

  const pagination = {
    el: ".swiper-pagination",
    clickable: true,
    renderBullet: (index, className) => {
      return `<span class=${className}></span>`;
    },
  };

  if (isLoading)
    return (
      <section className="main-slider" aria-label="ближайшие мероприятия">
        <div className="slider-container">
          <div className="slide">
            <div className="slide-description">
              <div className="description-content"></div>
            </div>
            <div className="slide-img"></div>
          </div>
        </div>
        <div className="slider-bullets">
          <div className="slider-bullet active"></div>
        </div>
      </section>
    );

  return (
    <section className="main-slider" aria-label="ближайшие мероприятия">
      <div className="slider-container">
        <Swiper
          loop
          modules={[Pagination, Navigation]}
          pagination={pagination}
          navigation={{
            prevEl: ".prev",
            nextEl: ".next",
          }}
        >
          {slides?.map((slide, id) => (
            <SwiperSlide key={id}>
              <div className="slide">
                <div className="slide-description">
                  <div className="description-content">
                    <h1 className="slide-title">{slide.title}</h1>
                    <span className="slide-date">
                      {slide.date.replace("-", "–")}
                    </span>
                    <p className="slide-text">{slide.short_desc}</p>
                    <Link
                      className="btn btn-border small"
                      to={`/event/${slide.slug}`}
                    >
                      Подробнее
                    </Link>
                  </div>
                </div>
                <Link className="slide-img" to={`/event/${slide.slug}`}>
                  <img src={`${BASE_URL}${slide.img}`} alt={slide.title} />
                </Link>
              </div>
            </SwiperSlide>
          ))}
          <ArrowLeft className="prev" />
          <ArrowRight className="next" />
        </Swiper>
      </div>
      <div className="swiper-pagination"></div>
    </section>
  );
};

export default MainSlider;
