import { Link } from "react-router-dom";

const Coupon = () => {
  return (
    <main className="coupon-main pad">
      <div className="container">
        <h3>Как активировать подарочный купон:</h3>
        <ul>
          <li>
            <div className="num">1</div>
            <p>
              <Link to="/login" className="text-link">
                Авторизуйтесь
              </Link>{" "}
              или{" "}
              <Link to="/signup" className="text-link">
                зарегистрируйтесь
              </Link>{" "}
              на сайте events.xbadm.ru
            </p>
          </li>
          <li>
            <div className="num">2</div>
            <p>
              На странице{" "}
              <Link to="/account" className="text-link">
                личного кабинета
              </Link>{" "}
              активируйте купон, нажав на кнопку “Активировать подарочный купон”
            </p>
          </li>
          <li>
            <picture>
              <source
                media="(max-width: 576px)"
                srcSet="/coupon-screenshot-mobile.jpg"
              />
              <img
                src="/coupon-screenshot.jpg"
                alt="Скриншот страницы личного кабинета"
              />
            </picture>
          </li>
          <li>
            <div className="num">3</div>
            <p>
              В открывшемся окне введите номер купона и нажмите кнопку
              “Активировать”
            </p>
          </li>
        </ul>
      </div>
    </main>
  );
};

export default Coupon;
