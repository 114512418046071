import { useEffect } from "react";
import {
  InputField,
  RadioGroup,
  RadioGroupWithValue,
  InputCheckbox,
  InputPhone,
} from "components/inputs";
import { FiEdit3, FiTrash2 } from "react-icons/fi";

import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import "yup-phone-lite";

import { useDispatch, useSelector } from "react-redux";
import { deleteParticipant, editParticipant } from "features/reservationSlice";
import { selectIsLoading } from "features/appSlice";
import { selectUser } from "features/userSlice";

const ParticipantForm = ({
  participant,
  participantNumber,
  eventId,
  suitId,
  participantId,
  suit,
  event,
}) => {
  const dispatch = useDispatch();
  const loading = useSelector(selectIsLoading);
  const user = useSelector(selectUser);
  const isClubPackage = suit.reservation_type === 2;

  const schema = Yup.object().shape({
    name: Yup.string()
      .required("Поле не может быть пустым")
      .matches(/^[а-яА-Я-a-zA-ZЁё\s]+$/, "Недопустимые символы")
      .min(3, "Должно содержать не менее трех букв"),
    phoneRequired: Yup.bool(),
    show_in_list: Yup.bool(),
    phone: Yup.string().when("phoneRequired", {
      is: true,
      then: Yup.string()
        .ensure()
        .required("Поле не может быть пустым")
        .phone("RU", "Некорректный номер телефона"),
      otherwise: Yup.string().notRequired(),
    }),
    food: Yup.string()
      .required("Необходимо выбрать тип питания")
      .min(3, "Необходимо выбрать тип питания")
      .nullable(),
    participantType: Yup.string()
      .required("Необходимо выбрать тип участника")
      .min(3, "Необходимо выбрать тип участника")
      .nullable(),
    kind_of_sport: Yup.string().when("participantType", {
      is: "Спортсмен",
      then: Yup.string().required("Необходимо выбрать вид спорта").nullable(),
      otherwise: Yup.string().notRequired(),
    }),
    age: Yup.string()
      .required("Необходимо выбрать возраст участника")
      .min(3, "Необходимо выбрать возраст участника")
      .nullable(),
    childAge: Yup.number().when("age", {
      is: "Ребенок (до 13 лет включительно)",
      then: Yup.number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .required("Поле не может быть пустым")
        .min(0, "Не может быть меньше нуля")
        .max(13, "Не может быть больше 13")
        .integer("Должно быть целым числом"),
      otherwise: Yup.number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .notRequired(),
    }),
    insurance: Yup.bool(),
  });
  const validationSchema = { resolver: yupResolver(schema) };

  const defaultValues = {
    ...participant,
    ...(isClubPackage && { food: "Стандартное" }),
    ...(isClubPackage && { participantType: "Спортсмен" }),
  };

  const {
    register,
    control,
    reset,
    setValue,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    ...validationSchema,
    mode: "all",
    defaultValues,
  });

  const data = useWatch({
    control: control,
  });

  useEffect(() => reset(), [reset]);

  const isPhoneRequired = data.phoneRequired || participant.phone;
  const isChild = data.age === "Ребенок (до 13 лет включительно)";
  const isFirst = !participantNumber || participantNumber === 1;
  const isSport = data.participantType === "Спортсмен";

  const showDeleteParticipantButton =
    !isFirst &&
    (isClubPackage ||
      (suit.options.type === "Номер целиком" &&
        suit.options.quantity > suit.min_capacity));

  // Fill main participant info
  const handleFillData = () => {
    setValue("name", user.name, { shouldValidate: true });
    setValue("phone", user.phone, { shouldValidate: true });
  };

  const handleDeleteParticipant = () => {
    dispatch(deleteParticipant({ eventId, suitId, participantId }));
  };

  // Set default age and required phone for 1st participant
  useEffect(() => {
    if (isFirst) {
      setValue("age", "Взрослый");
      setValue("phoneRequired", true);
    }
  }, [setValue, isFirst]);

  // Clear phone number
  const handleCheckPhone = (e) => {
    if (!e.target.checked) {
      setValue("phone", "");
    }
  };

  // Clear child age
  useEffect(() => {
    if (isChild) return;
    setValue("childAge", "");
  }, [setValue, isChild]);

  // Clear or Fill sport type
  useEffect(() => {
    if (isSport) {
      if (
        Array.isArray(event.available_sport_list) &&
        event.available_sport_list.length === 1
      ) {
        setValue("kind_of_sport", event.available_sport_list[0].value, {
          shouldValidate: true,
        });
      }
      return;
    }
    setValue("kind_of_sport", "");
  }, [setValue, isSport, event.available_sport_list]);

  // Redux
  useEffect(() => {
    dispatch(
      editParticipant({
        eventId,
        suitId,
        participantId,
        form: { ...data, valid: isValid },
      })
    );
  }, [dispatch, eventId, suitId, participantId, data, isValid, loading]);

  const onSubmit = () => {};

  return (
    <form
      id={"participant-form-" + participantId + "-" + suit.id}
      className="participant-form"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
    >
      <legend>
        <div className="legend-header">
          <h5>
            Данные {participantNumber && participantNumber + "-го"} участника
          </h5>
          {showDeleteParticipantButton && (
            <button
              title="Удалить участника"
              type="button"
              onClick={handleDeleteParticipant}
            >
              <FiTrash2 />
            </button>
          )}
        </div>
        {user && isFirst && (
          <button
            className="btn-fill"
            data-theme="light"
            type="button"
            onClick={handleFillData}
          >
            <FiEdit3 />
            Заполнить мои данные
          </button>
        )}
      </legend>
      <InputField
        label="Фамилия Имя"
        id={"participant-" + participantId + "-name-" + suit.id}
        type="text"
        {...register("name")}
        error={errors.name?.message}
      />
      <InputCheckbox
        label="Показывать имя в списке участников"
        {...register("show_in_list")}
      />
      {!isFirst && (
        <InputCheckbox
          label="Добавить номер телефона участника"
          onClick={handleCheckPhone}
          defaultChecked={participant.phone ? true : false}
          {...register("phoneRequired")}
        />
      )}
      {isPhoneRequired && (
        <InputPhone
          label="Телефон"
          name="phone"
          id={"participant-" + participantId + "-phone-" + suit.id}
          control={control}
          helperText="Потребуется для добавления в чат сборов"
          error={errors.phone?.message}
        />
      )}
      {!isClubPackage && (
        <>
          <RadioGroup
            label="Тип питания"
            arr={["Стандартное", "Вегетарианское"]}
            {...register("food")}
            error={errors.food?.message}
          />
          <RadioGroup
            label="Тип участника"
            arr={["Спортсмен", "Сопровождающий"]}
            {...register("participantType")}
            error={errors.participantType?.message}
          />
        </>
      )}

      {isSport && event.available_sport_list && (
        <RadioGroupWithValue
          label="Вид спорта"
          arr={event.available_sport_list}
          {...register("kind_of_sport")}
          error={errors.kind_of_sport?.message}
        />
      )}
      {!isFirst && (
        <RadioGroup
          label="Возраст участника"
          arr={["Взрослый", "Ребенок (до 13 лет включительно)"]}
          {...register("age")}
          error={errors.age?.message}
        />
      )}
      {isChild && (
        <InputField
          label="Полных лет на дату заезда"
          id={"participant-" + participantId + "-childage-" + suit.id}
          type="number"
          min="0"
          max="13"
          {...register("childAge", { valueAsNumber: true })}
          error={errors.childAge?.message}
        />
      )}
      <InputCheckbox
        label="Добавить страховку от невыезда (10% от стоимости пакета участника)"
        {...register("insurance")}
      />
    </form>
  );
};

export default ParticipantForm;
