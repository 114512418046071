import { InputField, InputPhone, InputTextArea } from "components/inputs";
import { BsCheckCircle } from "react-icons/bs";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import "yup-phone-lite";

import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "features/userSlice";
import { useRef, useState } from "react";
import { useSendSpecialRequestMutation } from "app/eventsApi";
import { setMessage } from "features/appSlice";

const SpecialRequestForm = () => {
  const successRef = useRef(null);
  const [isSent, setIsSent] = useState(false);

  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const [sendRequest] = useSendSpecialRequestMutation();

  const schema = Yup.object().shape({
    text: Yup.string().required("Поле не может быть пустым"),
    name: Yup.string().required("Поле не может быть пустым"),
    phone: Yup.string()
      .ensure()
      .required("Поле не может быть пустым")
      .phone("RU", "Некорректный номер телефона"),
  });

  const defaultValues = {
    text: "",
    name: user?.name,
    phone: user?.phone,
  };

  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
    mode: "all",
  });

  const onSubmit = async (data) => {
    try {
      await sendRequest({
        ...data,
        referer_url: window.location.href,
      }).unwrap();
      setIsSent(true);
      setTimeout(() => {
        successRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }, 50);
    } catch (err) {
      dispatch(
        setMessage({
          type: "error",
          msg: "Что-то пошло не так, попробуйте позже",
        })
      );
    }
  };

  return (
    <article className="special-request">
      {isSent ? (
        <div
          className="card special-request-card"
          data-success="true"
          ref={successRef}
        >
          <BsCheckCircle />
          <div className="request-legend">
            <h4>Запрос отправлен!</h4>
            <p>
              Ваш запрос был успешно отправлен. В ближайшее время с вами
              свяжется наш сотрудник по указанному вами номеру телефона
            </p>
          </div>
        </div>
      ) : (
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="card special-request-card"
        >
          <legend className="request-legend">
            <h4>Особое размещение</h4>
            <p>
              Не нашли подходящий вариант размещения или у вас есть специальный
              запрос? Напишите нам, заполнив эту форму:
            </p>
          </legend>
          <fieldset>
            <InputTextArea
              label="Мой запрос"
              id="request-text-input"
              type="text"
              {...register("text")}
              error={errors.text?.message}
            />
            <InputField
              label="Имя"
              id="request-name-input"
              {...register("name")}
              error={errors.name?.message}
            />
            <InputPhone
              label="Номер телефона"
              name="phone"
              id="request-phone-input"
              control={control}
              error={errors.phone?.message}
            />
          </fieldset>
          <button
            className="btn btn-primary"
            data-disabled={!isValid || undefined}
          >
            Отправить запрос
          </button>
        </form>
      )}
    </article>
  );
};

export default SpecialRequestForm;
