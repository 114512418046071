import { useState, useRef, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { ReactComponent as IconEmpty } from "icons/icon_empty.svg";
import SuitsList from "./SuitsList";
import EventCountdown from "./EventCountdown";
import EventSoldout from "./EventSoldout";
import { useCountDown, useDocumentTitle } from "hooks";

import { useDispatch, useSelector } from "react-redux";
import { selectLastLocation, setLastLocation } from "features/appSlice";

const Suits = ({ event, refetch }) => {
  useDocumentTitle(event.header);
  const [params, setParams] = useSearchParams();
  const searchPackage = params.get("package");
  const defaultPackage = event.packages.findIndex((el, id) =>
    searchPackage ? id === Number(searchPackage) : el.suits.length
  );
  const { timeLeft, days, hours, minutes, seconds } = useCountDown(
    event.sale_start || 0
  );

  const [selectedPackage, setSelectedPackage] = useState(
    defaultPackage !== -1 ? defaultPackage : 0
  );
  const suitsRef = useRef(null);

  const lastLocation = useSelector(selectLastLocation);
  const dispatch = useDispatch();

  const handleClick = (packageId) => {
    refetch();
    setSelectedPackage(packageId);
    setParams((p) => {
      p.set("package", packageId);
      return p;
    });
  };

  useEffect(() => {
    if (lastLocation === "/reservation") {
      dispatch(setLastLocation(null));
      suitsRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [lastLocation, dispatch]);

  if (timeLeft > 1)
    return (
      <EventCountdown
        days={days}
        hours={hours}
        minutes={minutes}
        seconds={seconds}
      />
    );

  return (
    <section className="event-suits" ref={suitsRef}>
      {event.sold_out ? (
        <EventSoldout />
      ) : (
        <>
          {event.packages.length > 1 && (
            <div className="package-select">
              {event.packages.map((el, id) => {
                return (
                  <button
                    className={
                      el.packageName ===
                      event.packages[selectedPackage].packageName
                        ? "btn btn-package"
                        : "btn btn-package active"
                    }
                    key={id}
                    disabled={
                      el.packageName ===
                      event.packages[selectedPackage].packageName
                    }
                    onClick={() => handleClick(id)}
                  >
                    {el.packageName}
                  </button>
                );
              })}
            </div>
          )}
          {!event.packages[selectedPackage].suits.length ? (
            <div className="suits-list-empty">
              <IconEmpty />
              <p>
                В данный момент нет доступных номеров на выбранные даты.
                Выберите другие даты или попробуйте позже
              </p>
            </div>
          ) : (
            <>
              <div className="package-title">
                <h4>{event.packages[selectedPackage].packageName}</h4>
                <p>Стоимость указана за одного человека</p>
              </div>
              <SuitsList
                event={event}
                selectedPackage={event.packages[selectedPackage]}
              />
            </>
          )}
        </>
      )}
    </section>
  );
};

export default Suits;
