import { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";

const UserAgreement = () => {
  const [text, setText] = useState("");

  useEffect(() => {
    import("md/user_agreement.md").then((res) => {
      fetch(res.default)
        .then((res) => res.text())
        .then((res) => setText(res));
    });
  });

  return (
    <main className="text-main pad">
      <div className="container">
        <h3>Пользовательское соглашение</h3>
        <ReactMarkdown children={text} />
      </div>
    </main>
  );
};

export default UserAgreement;
