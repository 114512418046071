import { api } from "./api";

const bonusApi = api
  .enhanceEndpoints({
    addTagTypes: ["Bonus", "BonusHistory", "Activity"],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getUserBonuses: builder.query({
        query: (userId) => `user_bonuses/?user_pk=${userId}`,
        transformResponse: (res) => res.data.bonuses,
        providesTags: ["Bonus"],
      }),
      getBonusHistory: builder.query({
        query: (userId) => `bonus_history/?user_pk=${userId}`,
        transformResponse: (res) => res.data.transactions,
        providesTags: ["BonusHistory"],
      }),
      bonusPay: builder.mutation({
        query: (args) => ({
          url: "bonus_pay/",
          method: "POST",
          body: args,
        }),
        invalidatesTags: ["Bonus"],
      }),
      getOldBonuses: builder.mutation({
        query: () => "old_event_bonuses/",
        invalidatesTags: ["Bonus", "BonusHistory"],
      }),
      activateCoupon: builder.mutation({
        query: (body) => ({
          url: "certs/activate_coupon",
          method: "POST",
          body,
        }),
        invalidatesTags: ["Bonus", "BonusHistory"],
      }),
      getUserActivity: builder.query({
        query: () => ({ url: "user_events_activity", credentials: "include" }),
        transformResponse: (res) => res.data.user_activity_data,
        providesTags: ["Activity"],
      }),
    }),
  });

export const {
  useGetUserBonusesQuery,
  useGetBonusHistoryQuery,
  useBonusPayMutation,
  useGetOldBonusesMutation,
  useActivateCouponMutation,
  useGetUserActivityQuery,
} = bonusApi;
